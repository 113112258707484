import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class IdGenService {
  
  public static genUUID() {
    return uuidv4().toUpperCase();
  }
  
  constructor(
   ) {  
  }

 public generateUUID() {
    return uuidv4().toUpperCase();
  }
  
}
