import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from './session.service';
import { QueryService } from './query.service';
import { UserService } from './user.service';
import { AuthorizationService } from './authorization.service';
import { SessionCheckService } from './session-check.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceLoginGuardService
  implements CanActivate, CanActivateChild
{
  constructor(
    private sessionService: SessionService,
    private queryService: QueryService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private sessionCheckService: SessionCheckService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canLogin();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canLogin();
  }

  /**
   *  Prevent logins during maintenance mode from a bookmarked Max Login page.
   *  Make an exception for testing using users_meta_info property.
   *  EFEDS-4626
   */
  canLogin(): Observable<boolean> {
    if (this.authorizationService.canUserLoginDuringMaintenance()) {
      return of(true);
    }

    return this.queryService.getLandingPageConfigs().pipe(
      map((configs) => {
        if (
          configs?.hideLoginOn === 'Yes' &&
          !this.canUserLoginDuringMaintenance()
        ) {
          this.router.navigate(['logout']);
          return false;
        }
        return true;
      })
    );
  }

  canUserLoginDuringMaintenance(): boolean {
    if (
      this.sessionCheckService.metaInfoRecord.results &&
      this.sessionCheckService.metaInfoRecord.results.data &&
      this.sessionCheckService.metaInfoRecord.results.data
        .allowMaintenanceLogin !== undefined
    ) {
      return this.sessionCheckService.metaInfoRecord.results.data
        .allowMaintenanceLogin;
    }
    return false;
  }
}
