<ng-template #sessionWarning let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-session-title">
      Session Expiration Warning
    </h4>
    <button
      type="button"
      class="modal-close"
      (click)="endSession()"
      aria-hidden="true"
    ></button>
  </div>
  <div class="modal-body">
    <p>Your session will expire shortly.</p>
    <p>Please indicate what you'd like to do now.</p>
    <p>If you do nothing, your session will end automatically.</p>
  </div>
  <div class="modal-footer">
    <button class="btn" aria-hidden="true" (click)="endSession()">
      Log Out Now
    </button>
    <button class="btn yellow" aria-hidden="true" (click)="extendSession()">
      Extend My Session
    </button>
  </div>
</ng-template>
