import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../../shared/services/config.service';
import { SessionService } from '../../shared/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private sessionService: SessionService,
    private http: HttpClient
  ) {}

  handleError(error: Error) {
    const userAgent = {
      language: navigator.language,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      /* 
      OGE-7650 Temporarily comment out navigator.connection code that breaks compiler
      error TS2339: Property 'downlink' does not exist on type 'NetworkInformation'.
      error TS2339: Property 'effectiveType' does not exist on type 'NetworkInformation'.
      */
      // connectionDownlink: navigator['connection'].downlink,
      // connectionEffectiveType: navigator['connection'].effectiveType,
    };
    const body = {
      timestamp: Date.now(),
      message: error.message,
      userId: this.sessionService.getMaxUsername(),
      location,
      userAgent,
      stack: error.stack,
    };

    this.http
      .post(ConfigService.LOG_ERROR, body, this.getOptions())
      .subscribe(() => {
        console.dir(body);
      });

    console.error(error);
  }

  private getOptions(): any {
    return { headers: this.getHeaders() };
  }

  private getHeaders(): HttpHeaders {
    return this.sessionService
      .getAuthHeader()
      .append('x-session-user-id', this.sessionService.getMaxUsername());
  }
}
