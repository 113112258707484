<div
  id="page-sidebar-menu-div"
  class="page-sidebar"
  *ngIf="menuService.visible"
>
  <ul class="page-sidebar-menu" *ngIf="!menuService._showOnlyClose">
    <li class="no-hover">
      <div
        class="sidebar-toggler hidden-phone"
        ngbTooltip="Collapse/Expand"
        placement="right"
        tooltipClass="hamburger-tooltip"
        tabindex="1"
        aria-label="Collapse or expand navigation menu"
      ></div>
    </li>
    <li>
      <span class="white" *ngIf="filerName">
        <i class="icon-user"></i>
        <span class="title">{{ filerName }}</span>
      </span>
    </li>
    <li
      class="no-hover menu-view-toggler-container"
      *ngIf="
        toggleCount > 1 && mode != modes.Filing && mode != modes.ReviewFiling
      "
    >
      <div
        class="btn-group menu-view-toggler pb-3"
        [ngClass]="{ 'exactly-two-buttons': toggleCount == 2 }"
        xxx-data-toggle="buttons-radio"
      >
        <button
          style="color: #333 !important"
          type="button"
          class="btn btn-primary"
          title="Filer Mode"
          (click)="changeMode('/filer')"
          [ngClass]="{ active: mode == modes.Filer }"
          *ngIf="showFilerToggle"
        >
          F<span>iler</span>
        </button>
        <button
          style="color: #333 !important"
          type="button"
          class="btn btn-primary"
          title="Reviewer Mode"
          (click)="changeMode('/reviewer')"
          [ngClass]="{ active: mode == modes.Reviewer }"
          *ngIf="showReviewerToggle"
        >
          R<span>eviewer</span>
        </button>
        <button
          style="color: #333 !important"
          type="button"
          class="btn btn-primary"
          title="Administrator Mode"
          (click)="changeMode('/admin')"
          [ngClass]="{ active: mode == modes.Admin }"
          *ngIf="showAdminToggle"
        >
          A<span>dmin</span>
        </button>
      </div>
    </li>
    <ng-container *ngIf="!!menuService && !rebuilding">
      <integrity-filer-menu-items
        *ngIf="mode == modes.Filer"
      ></integrity-filer-menu-items>
      <integrity-filing-menu-items
        *ngIf="mode == modes.Filing"
      ></integrity-filing-menu-items>
      <integrity-review-filing-menu-items
        *ngIf="mode == modes.ReviewFiling"
      ></integrity-review-filing-menu-items>
      <integrity-reviewer-menu-items
        *ngIf="mode == modes.Reviewer"
      ></integrity-reviewer-menu-items>
      <integrity-admin-menu-items
        *ngIf="mode == modes.Admin"
      ></integrity-admin-menu-items>
    </ng-container>
  </ul>
  <ul class="page-sidebar-menu" *ngIf="menuService._showOnlyClose">
    <li class="no-hover">
      <div
        class="sidebar-toggler hidden-phone"
        hover-text
        hover-text-content="Collapse/Expand"
        hover-text-offset="30"
        tabindex="1"
        aria-label="Collapse or expand navigation menu"
      ></div>
    </li>
    <li>
      <a (click)="subWindows.closeSubWindow()">
        <i class="fas fa-times-circle"></i>
        <span class="title">CLOSE WINDOW</span>
      </a>
    </li>
  </ul>
  <integrity-test-site-warning></integrity-test-site-warning>
</div>
