import { Injectable } from '@angular/core';
import { QueryService } from './query.service';
import { Observable, forkJoin, throwError } from 'rxjs';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { SessionService } from './session.service';
import { HttpClient } from '@angular/common/http';
import { FormDataPersistenceService } from './form-data-persistence.service';
import { Document } from '../models/document.model';
import { LogService } from './log.service';

const DEFAULT_TABLENAME: string = 'filing_documents';

@Injectable({
  providedIn: 'root',
})
export class DocumentsUtilitiesService {
  constructor(
    private queryService: QueryService,
    private http: HttpClient,
    private sessionService: SessionService,
    private formDataPersistenceService: FormDataPersistenceService
  ) {}

  public saveDocument(
    docMetaData: Document,
    file: File,
    documentTablename: string | null
  ): Observable<any> {
    return this.saveDocumentByFilename(
      docMetaData,
      file,
      documentTablename,
      null
    );
  }

  public saveDocumentByFilename(
    docMetaData: Document,
    file: any,
    documentTablename: string | null,
    filename: string | null
  ): Observable<any> {
    if (documentTablename == null) {
      documentTablename = '';
    }
    let tableName: string = this.getDocumentTableName(documentTablename);
    filename = filename ? filename : this.getFileName(file);

    let url =
      `${ConfigService.MAX_PAAS_DOCUMENTS_PERSISTENCE_URL}` +
      docMetaData.scope +
      '/' +
      filename;

    let contentType: string = this.getContentType(file);
    let headers: any = {
      'Content-Type': contentType,
    };

    // Save the file to the server
    return this.http
      .post<any>(
        url,
        file, // Send the File Blob as the POST body.
        {
          headers: headers,
        }
      )
      .pipe(
        mergeMap((data) => {
          if (data.statusMessage == 'OK') {
            // Save the file details to database table
            return this.formDataPersistenceService.save(
              tableName,
              docMetaData.documentId,
              docMetaData,
              false
            );
          } else {
            return throwError(data.statusMessage);
          }
        })
      );
  }

  public getContentType(file: any): string {
    let contentType: string = file.type;

    if (contentType == '') {
      if (/\.msg$/gi.test(file.name)) {
        contentType = 'application/vnd.ms-outlook';
      } else if (/\.pdf$/gi.test(file.name)) {
        contentType = 'application/pdf';
      } else {
        contentType = 'application/octet-stream';
      }
    }

    return contentType;
  }
  
  updateDocument(documentTablename: string, docMetaData: Document): Observable<any> {
    const tableName = this.getDocumentTableName(documentTablename);

    return this.formDataPersistenceService.save(
      tableName,
      docMetaData.documentId,
      docMetaData,
      false
    );
  }

  /**
   * Delete an uploaded file from the server and the filing_documents table.
   */
  public deleteDocument(
    docMetaData: Document,
    documentTablename: string = ''
  ): Observable<any> {
    documentTablename = this.getDocumentTableName(documentTablename);

    // Delete the document record from the database table
    const obs1$: Observable<any> = this.formDataPersistenceService.delete(
      documentTablename,
      docMetaData.documentId
    );

    // Delete the file from the server
    const filename = this.getFileName(docMetaData);
    const serviceEndpoint =
      `${ConfigService.MAX_PAAS_DOCUMENTS_PERSISTENCE_URL}` +
      docMetaData.scope +
      '/' +
      filename;
    const obs2$: Observable<any> = this.http.delete(serviceEndpoint, {
      headers: {
        'x-user-id-column': 'user_id',
        'x-user-id-value': this.sessionService.getMaxUsername(),
      },
    });

    return forkJoin([obs1$, obs2$]).pipe(
      tap(() => {}),
      catchError((error) => {
        return throwError(`Error deleting document`);
      })
    );
  }

  public getDocumentTableName(documentTablename: string): string {
    return documentTablename ? documentTablename : DEFAULT_TABLENAME;
  }

  public getFileName(file: Document): string {
    return file.name.substring(0, file.name.lastIndexOf('.'));
  }

  /**
   * Retrieve all filing documents and check if any of them are a job description type.
   */
  public getJobDescription(filingId: string): Observable<Document | null> {
    let jobDescription: Document | null = null;

    return this.queryService.getFilingDocuments(filingId).pipe(
      map((documents: Document[]) => {
        documents.forEach(function (document: Document) {
          if (document.type == 'job_description') {
            jobDescription = document;
          }
        });
        return jobDescription;
      })
    );
  }
}
