import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {    
  }
  
  /**
   * Check if the user has permission to Reviewer and/or Admin
   * menu toggles.
   */
  canActivate(route: ActivatedRouteSnapshot): boolean {

    const expectedRole = route.data.expectedRole;

    if(!this.sessionService.menuToggles.includes(expectedRole)) {
      this.router.navigate(['/unauthorized']);
    }
   
    return true;
  }  
}
