import { ValueOption } from './value-option.model';

export class ValueOptions {
  asArray: Array<ValueOption> = [];
  asMap: Map<string, ValueOption> = new Map();

  constructor() {}

  static create(data: any): ValueOptions {
    let options: ValueOptions = new ValueOptions();

    if (
      (data.status != undefined && data.status != 0) ||
      !data.results ||
      !data.results.array ||
      !Array.isArray(data.results.array)
    ) {
      return options;
    }

    data.results.array.forEach(function (option) {
      options.addOption(option.value, option.label, option.gridDisplay);
    });

    return options;
  }

  get options() {
    return this.asArray;
  }

  set options(options: ValueOption[]) {
    options.forEach((option) => this.index(option));
  }

  /**
   * Insert a placeholder value at the top of the array, e.g., "Please Select"
   */
  addPlaceholder(placeholder: string) {
    const opt = new ValueOption('', placeholder);
    if (!this.optionExists(opt)) {
      this.asArray.unshift(opt);
      this.asMap.set('', opt);
    }
  }

  addOption(
    value: string,
    display: string,
    gridDisplay: string | undefined = undefined
  ) {
    const opt = new ValueOption(value, display, gridDisplay);

    if (this.optionExists(opt)) {
      console.log(
        '-> duplicate option ' + opt.value + ' is rejected from ValueOptions'
      );
      return;
    }
    this.asArray.push(opt);
    this.asMap.set(value, opt);
  }

  removeOption(target: string): void {
    this.asArray.forEach((option, index) => {
      if (option.value === target) {
        this.asArray.splice(index, 1);
      }
    });
    this.asMap.delete(target);
  }

  index(option: ValueOption) {
    this.asArray.push(option);
    this.asMap.set(option.value + '', option);
  }

  /**
   * Check if option already exists in the array before adding.
   * The checkbox group "separator", e.g., Underlying Holders Income Type is an exception to this rule.
   * It is displayed as "---" and has no checkbox.
   *
   * @param opt
   */
  optionExists(opt: ValueOption): boolean {
    const exists = this.asArray.find(
      (option) => opt.value === option.value && opt.value != 'separator'
    );

    return exists ? true : false;
  }

  getOption(value: string) {
    return this.asMap.get(value);
  }
}
