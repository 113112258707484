import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'integrity-not-a-filer-modal',
  templateUrl: './not-a-filer-modal.component.html',
  styleUrls: ['./not-a-filer-modal.component.scss']
})
export class NotAFilerModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) {
    
  }

  ngOnInit(): void {
  }

}
