import { Role, RoleKey } from './role.model';
import { Signature } from './signature.model';
import { Status } from './status.model';
import _ from 'lodash-es';

export class Workflow {
  name: string;
  status: Status;
  history: Status[] = [];

  can_return_to: string[] = [];
  holder: string;
  holderFullName: string;
  holders: string[];
  nomineePreclearance: boolean;
  nomineePrerelease: boolean;
  role: Role | undefined;
  step: number;
  theEnd: boolean = false;
  signatures: Signature[] = [];
  requiresNominationDate: boolean;
  lastSignee: string;
  lastSigneeId: string;

  constructor(status: Status) {
    this.status = status;
  }

  static createFromJson(data: any, theEnd: boolean): Workflow {
    let workflow: Workflow;
    if (!!data.current) {
      const status = new Status(data.current.status);
      status.timeStamp = data.current.timestamp;
      status.by = data.current.by;
      status.workflowStep = parseInt(data.current.workflowStep);
      workflow = new Workflow(status);
      workflow.theEnd = theEnd;
      workflow.step = status.workflowStep;
      if (!!data.history && data.history.length > 0) {
        data.history.forEach((item: any) => {
          let status = new Status(item.status);
          status.timeStamp = item.timestamp;
          status.by = item.by;
          status.workflowStep = parseInt(item.workflowStep);
          workflow.history.push(status);
        });
      }
    } else {
      const status = new Status(data.status);
      status.workflowStep = parseInt(data.step);
      workflow = new Workflow(status);
      workflow.can_return_to = !!data.can_return_to
        ? JSON.parse(data.can_return_to) : workflow.can_return_to;
      workflow.holder = data.holder;
      workflow.holders = !!data.holders ? JSON.parse(data.holders) : [];
      workflow.holderFullName = data.holderFullName;
      workflow.nomineePreclearance = _.isString(data.nominee_preclearance)
        ? data.nominee_preclearance === 'true'
        : data.nominee_preclearance;
      workflow.nomineePrerelease = _.isString(data.nominee_prerelease)
        ? data.nominee_prerelease === 'true'
        : data.nominee_prerelease;
      workflow.role = !!data.role
        ? new Role(data.role_surrogate_key, data.role_type)
        : undefined;
      if (workflow.role) {
        workflow.role.label = data.role;
      }
      workflow.step = parseInt(data.step);
      workflow.theEnd = theEnd;
    }

    return workflow;
  }

  static workflowStepNumberForRole(
    role: string,
    workflows: Workflow[]
  ): number {
    for (const workflow of workflows) {
      if (workflow.role?.getSurrogateKey() === role) {
        return workflow.step;
      }
    }

    return -1;
  }

  toJson(): any {
    const json: any = {};
    for (const prop of Object.keys(this)) {
      switch (prop) {
        case 'status': {
          json.current = this.status.toJson();
          break;
        }
        case 'history': {
          json.history =
            !!this.history && this.history.length > 0
              ? this.history.map((status) => status.toJson())
              : [];
          break;
        }
        default: {
          //json[prop] = (this as any)[prop];
        }
      }
    }
    return json;
  }
}
