import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionService } from './session.service';
import { ConfigService } from './config.service';
import { catchError, mergeMap, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { ReportUtilitiesService } from './report-utilities.service';
import {
  AdditionalFormValues,
  ConfigModel,
  Fields,
  GroupSurrogateKey,
  Routing,
} from '../models/config.model';
import { Filing } from '../models/filing.model';
import { FormDataPersistenceService } from './form-data-persistence.service';
import { QueryService } from './query.service';
import _ from 'lodash-es';

interface WizardData {
  filing: Filing;
  formValues: any;
  surrogateKey: string;
  config?: ConfigModel;
}

class Params {
  dssRowName = 'results';
  dssGroupedByElement = 'rows';
  queryParams: HttpParams;
  rowTransformCallback?: Function;

  constructor(queryParams: HttpParams) {
    this.queryParams = queryParams;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FormsTemplatePersistenceService {
  static readonly nonFormTables = [
    'filing',
    'filing_documents',
    'agency_documents',
    'users_meta_info',
    'user_login',
    'user_import_job',
    'performance_test_results',
  ];

  constructor(
    private configService: ConfigService,
    private sessionService: SessionService,
    private reportService: ReportUtilitiesService,
    private formDataService: FormDataPersistenceService,
    private http: HttpClient,
    private queryService: QueryService
  ) {}

  // Converts a formId to it's associated database table name
  static getFormTableName(formId: string) {
    formId = formId.toLowerCase();

    if (FormsTemplatePersistenceService.nonFormTables.includes(formId)) {
      return formId;
    }

    return 'form_' + formId;
  }

  private static getReportType(filing: Filing): string {
    let reportType = filing.item;

    // strip off the word "Report" and everything after it
    // e.g. "Nominee Report" becomes "Nominee"
    reportType = reportType.substring(0, reportType.indexOf(' Report'));

    if (!!filing.rulesVersion) {
      reportType += ' v' + filing.rulesVersion;
    }

    return reportType;
  }

  public get(formId: string): Observable<any> {
    return this.http
      .get(
        `${ConfigService.MAX_PAAS_FORM_TEMPLATE_PERSISTENCE_URL}${formId}.json`
      )
      .pipe(
        mergeMap((data: any) => {
          if (data.status === 0) {
            return of(data.results);
          } else {
            return throwError(data.statusMessage);
          }
        })
      );
  }

  getFormConfigs(formId: string): Observable<ConfigModel> {
    const jsonFileName =
      'assets/json/config-' + formId.replace(/\s+/g, '_') + '.json';

    return this.http
      .get<any>(jsonFileName)
      .pipe(map((json) => new ConfigModel(json)));
  }

  /**
   * Retrieve the form values from the table, e.g., form_1_0_contact_info.
   * For non-filing form tables, the table may not have data yet.
   */
  getFormValues(filingId: string, formId: string): Observable<any> {
    const endpoint =
      ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_RETRIEVE +
      FormsTemplatePersistenceService.getFormTableName(formId) +
      '.surrogate_key/placeholder/' +
      filingId +
      '.json';
    return this.http
      .get<any>(endpoint, { headers: this.sessionService.getAuthHeader() })
      .pipe(
        map((data) => {
          let formValues: any = {};
          if (data.status === 0 && !!data.results.data) {
            formValues = Object.assign({}, JSON.parse(data.results.data), {version: data.results.version});
          }

          return formValues;
        })
      );
  }

  /**
   * Retrieves the initial form values based on the form and filing Id,
   * as well as the filing information and configuration information.
   * If additional form values are needed based on the configuration,
   * retrieve and update the form values accordingly
   * @param filingId
   * @param formId
   * @param secondaryId optional
   * @param positionId optional
   */
  getFormData(
    filingId: string,
    formId: string,
    secondaryId?: string,
    positionId?: string
  ): Observable<WizardData> {
    const documentId: string = !secondaryId ? filingId : secondaryId;
    let documentSurrogateKey = '';

    // Load the filing and existing record from Form Data Persistence
    // Set it to "this.data" to feed it in to the MAX Form component
    return forkJoin({
      formValues: this.getFormValues(documentId, formId),
      filing: this.reportService.getFiling(filingId),
      config: this.getFormConfigs(formId),
    }).pipe(
      switchMap((result) => {
        const filing: Filing = result.filing;
        const formValues = result.formValues;
        formValues.reportType = FormsTemplatePersistenceService.getReportType(
          result.filing
        );
        formValues.filingType = result.filing.filingType;
        const config = result.config;

        documentSurrogateKey = !secondaryId
          ? result.filing.filingId
          : secondaryId;
        const wizardData = {
          filing,
          formValues,
          surrogateKey: documentSurrogateKey,
          config,
        };

        // If no additional values, just return filing, formValues and surrogate key as is
        // Otherwise, get additional form data as required
        if (result.config.additionalFormValues === undefined) {
          return of(wizardData);
        } else {
          return this.getAdditionalFormData(
            result.config.additionalFormValues,
            filingId,
            positionId
          ).pipe(
            mergeMap((data) => {
              return this.getFields(
                result.config.additionalFormValues?.fields,
                data,
                wizardData
              );
            })
          );
        }
      })
    );
  }

  /**
   * Retrieves additional form values
   * @param additional
   * @param filingId
   * @param positionId
   */
  getAdditionalFormData(
    additional: AdditionalFormValues,
    filingId: string,
    positionId?: string
  ): Observable<any> {
    if (additional.source === 'grid') {
      return this.getGridData(
        'grid_row_data',
        filingId,
        additional,
        positionId
      );
    } else if (
      additional.source === 'users_meta_info' &&
      additional.id === 'userid'
    ) {
      return this.formDataService.get(
        'users_meta_info',
        this.sessionService.getMaxUsername()
      );
    } else {
      return this.formDataService.get(additional.id, filingId);
    }
  }

  /**
   * Retrieves grid data for form values
   *
   * Convert the results into an object with the format expected for function getFields which references
   * the object as data.results.data
   *
   * @param gridRowData
   * @param filingId
   * @param additional
   * @param positionId
   * @private
   */
  private getGridData(
    gridRowData: string,
    filingId: string,
    additional: AdditionalFormValues,
    positionId?: string
  ): Observable<any> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_NODE_BASE + gridRowData;

    const params: any = {
      queryParams: {
        tablename: 'grid_' + additional.id,
        filingId,
        id: '',
      },
    };

    if (additional.id === 'positions' && !!positionId) {
      params.queryParams.id = positionId;
    }

    return this.queryService.get(endpoint, params).pipe(
      map((rows: any) => {
        return { results: { data: rows } };
      }),
      catchError(() => {
        return of({ status: 50, statusMessage: 'Not Found' });
      })
    );
  }

  /**
   * Gets field data updates based on the additional configuration required
   * @param fields
   * @param data
   * @param wizardData
   * @private
   */
  private getFields(
    fields: Fields | undefined,
    data: any,
    wizardData: WizardData
  ): Observable<WizardData> {
    const rows: any[] = (data.results.data = this.massageDataStructure(data));

    const observables: Observable<any>[] = [];
    for (const field in fields) {
      if (fields.hasOwnProperty(field)) {
        for (const row of rows) {
          const sourceValue = this.getAdditionalFieldSourceFromRow(row, field);
          if (!!sourceValue) {
            if (_.isArray(fields[field])) {
              fields[field].forEach((elem: GroupSurrogateKey) => {
                if (_.isObject(elem)) {
                  switch (elem.action) {
                    case 'groupNameLookup': {
                      observables.push(
                        this.getGroupName(sourceValue).pipe(
                          tap((name) => {
                            if (
                              !!name &&
                              typeof elem.destination === 'string'
                            ) {
                              wizardData.formValues[elem.destination] = name;
                            }
                          })
                        )
                      );
                      break;
                    }
                    case 'parentAgencyLookup': {
                      observables.push(
                        this.getParentAgencyName(sourceValue).pipe(
                          tap((name) => {
                            if (
                              !!name &&
                              typeof elem.destination === 'string'
                            ) {
                              wizardData.formValues[elem.destination] = name;
                            }
                          })
                        )
                      );
                      break;
                    }
                    case 'namesLookup': {
                      const userid = this.sessionService.getMaxUsername();
                      observables.push(
                        this.namesLookup(userid, wizardData, elem)
                      );
                      break;
                    }
                  }
                }
              });
            } else if (_.isObject(fields[field])) {
              wizardData.formValues[fields[field].destination] = sourceValue;
              //
              // if(fields[field].overwrite) {
              //   delete formValues[field].destination];
              // }
            } else {
              wizardData.formValues[fields[field]] = sourceValue;
            }
          }
        }
      }
    }

    // If we don't have any observables from the above loop, create a dummy one for the forkJoin below
    if (!observables.length) {
      observables.push(of(wizardData));
    }

    // map results of all observables to return the updated form values
    return forkJoin(observables).pipe(
      map((results) => {
        return wizardData;
      })
    );
  }

  /**
   * Handle the case where additional field data retrieved have key properties in upper case keys
   *  whereas the config expects camel case.
   */
  private getAdditionalFieldSourceFromRow(row: any, field: string) {
    if (row.hasOwnProperty(field)) {
      return row[field];
    } else if (row.hasOwnProperty(field.toUpperCase())) {
      return row[field.toUpperCase()];
    } else if (row.hasOwnProperty('data') && row.data.hasOwnProperty(field)) {
      return row.data[field];
    } else if (
      row.hasOwnProperty('data') &&
      row.data.hasOwnProperty(field.toUpperCase())
    ) {
      return row.data[field.toUpperCase()];
    }

    return null;
  }

  /**
   * Handle the various types of data structures we may get back for additional form fields.
   * The data might be in data.results or data.results.data and data.results.data could be
   * a single object or an array.
   *
   * Also, data.results.data array could contain objects within the property key "data".
   *
   * EFEDS-6335 / EFEDS-6320
   */
  private massageDataStructure(data: any) {
    let rows: any[] = [];

    if (!data.results.data) {
      rows.push(data.results);
    } else if (_.isArray(data.results.data)) {
      rows = data.results.data;
    } else {
      rows.push(data.results.data);
    }

    return rows;
  }

  /**
   * Gets group name for form value
   * @param groupId
   */
  private getGroupName(groupId: string): Observable<string | undefined> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_GET_GROUP_NAME;
    const params = {
      params: {
        groupId,
      },
      headers: this.sessionService.getNodeHeader(),
    };
    
    return this.http.get<any>(endpoint, params).pipe(
      map((data) => {
        if (!!data.results?.group?.name) {
          return data.results.group.name;
        } else {
          return undefined;
        }
      })
    );
  }

  /**
   * Retrieves agency name for form values
   * @param groupId
   */
  private getParentAgencyName(groupId: string): Observable<string | undefined> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_GET_PARENT_AGENCY_NAME;
    const params = {
      headers: this.sessionService.getNodeHeader(),
      params: { groupId },
    };
    return this.http.get<any>(endpoint, params).pipe(
      map((data) => {
        if (!!data.results?.agency?.name) {
          return data.results.agency.name;
        } else {
          return undefined;
        }
      })
    );
  }

  /**
   * Retrieves name lookup service to check if requesting user is a designee.
   * If they are, retrieves and updates form values with actual filer's name
   * otherwise, return empty
   * @param userId
   * @param wizardData
   * @param elem
   * @private
   */
  private namesLookup(
    userId: string,
    wizardData: WizardData,
    elem: any
  ): Observable<any> {
    return this.formDataService.get('users_meta_info', userId).pipe(
      switchMap((lookup) => {
        if (lookup.status === 0 && lookup.results.data.type === 'DESIGNEE') {
          userId = lookup.results.data.filerId;
        }
        return this.updateNames(userId, wizardData, elem);
      })
    );
  }

  /**
   * Retrieves the contact info for a user and updates the form values
   * with the correct values
   * @param userId
   * @param wizardData
   * @param elem
   * @private
   */
  private updateNames(
    userId: string,
    wizardData: WizardData,
    elem: any
  ): Observable<any> {
    return this.formDataService.get('1_0_Contact_Info', userId).pipe(
      tap((lookupData) => {
        for (const destination in elem.destination) {
          if (lookupData.results.data.hasOwnProperty(destination)) {
            wizardData.formValues[elem.destination[destination]] =
              lookupData.results.data[destination];
          }
        }
      })
    );
  }
}
