import { Filing } from './filing.model';
import { Role, RoleType } from './role.model';

export class AllowedReportStatuses {
  general: string[];
  confirmed: string[];

  static createFromJson(json: any) {
    const allowedReportStatuses = new AllowedReportStatuses();
    if (json) {
      allowedReportStatuses.general = json.GENERAL_278;
      allowedReportStatuses.confirmed = json.CONFIRMED_278;
    }

    return allowedReportStatuses;
  }
  isEmpty() {
    return (
      (!this.general || !this.general.length) &&
      (!this.confirmed || !this.confirmed.length)
    );
  }

  isMatch(filing: Filing) {
    if (this.isEmpty()) {
      return true;
    }
    if (filing) {
      const target =
        filing.filingType == 'GENERAL_278' ? this.general : this.confirmed;
      if (target && target.length) {
        return target.includes(filing.workflow.status.status);
      }
    }
    return false;
  }
}

export class MenuItem {
  title: string;
  iconClass: string;
  allowedReportTypes: string[];
  items: MenuItem[] = [];
  parameterizedUrls: string[];
  urls: string[];
  href: string;
  allowedReportStatuses: AllowedReportStatuses;
  disallowedRoles: string[];
  allowedReportPurged: boolean;
  allowedRoleTypes: RoleType[];
  isActive: boolean = false;
  isEnabled: boolean = false;
  flagFunction: string; // Observable identifier that determines menu checkmark
  flagEnabled: boolean = false; // Display/hide item checkmark icon

  // Compare:
  compareTag: string;
  action: string;

  static createFromJson(json: any): MenuItem {
    const menuItem = new MenuItem();
    menuItem.title = json.title;
    menuItem.iconClass = json.class_icon;
    menuItem.flagFunction = json.flag_function ?? '';
    menuItem.parameterizedUrls = json.urls;
    menuItem.flagFunction = !!json.flag_function ? json.flag_function : '';
    menuItem.compareTag = !!json.compare_tag ? json.compare_tag : '';
    menuItem.action = !!json.action ? json.action : '';
    if (json.urls && json.urls.length) {
      menuItem.href = json.urls[0];
    }
    menuItem.allowedReportTypes = json.allowedReportTypes;
    menuItem.allowedReportStatuses = AllowedReportStatuses.createFromJson(
      json.allowedReportStatuses
    );
    menuItem.disallowedRoles = json.disallowedRoles;
    menuItem.allowedReportPurged = json.allowedReportPurged;
    if (json.allowedRoleTypes) {
      menuItem.allowedRoleTypes = json.allowedRoleTypes.map((roleType) => {
        return Role.convertRoleType(roleType);
      });
    }

    if (json.items && json.items.length) {
      json.items.forEach((item) => {
        menuItem.items.push(MenuItem.createFromJson(item));
      });
    }
    return menuItem;
  }

  setEnabled(filing: Filing, isDesignee: boolean) {
    this.isEnabled =
      this.isAllowedReportType(filing) &&
      this.isAllowedReportStatus(filing) &&
      (!isDesignee || (isDesignee && !this.isDisallowedRole('DESIGNEE'))) &&
      this.isAllowedReportPurged(filing);

    this.items.forEach((item, idx) => {
      item.setEnabled(filing, isDesignee);
    });
  }

  isAllowedReportType(filing: Filing) {
    if (filing && this.allowedReportTypes.length) {
      return this.allowedReportTypes.includes(filing.item);
    }
    return false;
  }

  isAllowedReportStatus(filing: Filing) {
    if (filing && this.allowedReportStatuses) {
      return this.allowedReportStatuses.isMatch(filing);
    }
    return false;
  }

  isDisallowedRole(role) {
    return this.disallowedRoles && this.disallowedRoles.includes(role);
  }

  isAllowedReportPurged(filing: Filing) {
    if (
      filing &&
      filing.purged &&
      this.allowedReportPurged != undefined &&
      !this.allowedReportPurged
    ) {
      return false;
    }
    return true;
  }

  isAllowedRoleType(type: RoleType) {
    return this.allowedRoleTypes && this.allowedRoleTypes.includes(type);
  }

  replaceUrlParams(
    filing: Filing,
    positionId: string | null,
    secondaryId: string | null
  ) {
    // Bring back the parameterized urls before running the replacements
    if (this.parameterizedUrls) {
      this.urls = this.parameterizedUrls.slice();
      if (this.urls.length) {
        this.href = this.urls[0];
      }
    }

    if (this.href) {
      this.href = this.href.replace('{{filingId}}', filing.filingId);
      if (positionId) {
        this.href = this.href.replace('{{positionId}}', positionId);
      }
      if (secondaryId) {
        this.href = this.href.replace('{{secondaryId}}', secondaryId);
      }
    }
    if (this.urls) {
      for (let i = 0; i < this.urls.length; i++) {
        this.urls[i] = this.urls[i].replace('{{filingId}}', filing.filingId);
        if (positionId) {
          this.urls[i] = this.urls[i].replace('{{positionId}}', positionId);
        }
        if (secondaryId) {
          this.urls[i] = this.urls[i].replace('{{secondaryId}}', secondaryId);
        }
      }
    }

    this.items.forEach((item) => {
      item.replaceUrlParams(filing, positionId, secondaryId);
    });
  }

  setActive(url: string) {
    this.isActive = this.matchesUrl(url);

    this.items.forEach((item) => {
      this.isActive = item.setActive(url) || this.isActive;
    });

    return this.isActive;
  }

  matchesUrl(url: string): boolean {

    // Remove query params from url
    const baseUrl = url.split('?')[0];

    return this.urls && this.urls.includes(baseUrl);
  }

  disable(title: string) {
    this.items.forEach((item) => {
      if (item.title === title) {
        item.isEnabled = false;

        item.disable(title);
      }
    });
  }
}

export class MenuItems {
  items: MenuItem[] = [];
  filing: Filing;

  static createFromJson(json: any): MenuItems {
    // TODO optional: switch to https://github.com/typestack/class-transformer
    const menu = new MenuItems();

    if (json.items && json.items.length) {
      json.items.forEach((item) => {
        menu.items.push(MenuItem.createFromJson(item));
      });
    }

    return menu;
  }

  setFiling(filing: Filing) {
    this.filing = filing;
  }

  prepare(
    checkHighwater: boolean,
    isDesignee: boolean,
    positionId: string | null,
    secondaryId: string | null
  ) {
    this.items.forEach((item) => {
      item.replaceUrlParams(this.filing, positionId, secondaryId);
      item.setEnabled(this.filing, isDesignee);
    });

    if (checkHighwater && this.items.length) {
      this.items[0].items.forEach((item, idx) => {
        if (
          idx > this.filing.navHighwaterIndex &&
          item.title != 'Printable View'
        ) {
          item.isEnabled = false;
        }
      });
    }
  }

  setActive(url: string) {
    this.items.forEach((item) => {
      item.setActive(url);
    });
  }

  getIndex(url: string): number {
    let index = -1;
    if (this.items.length) {
      this.items[0].items.forEach((item, idx) => {
        if (item.matchesUrl(url)) {
          index = idx;
        }
      });
    }
    return index;
  }

  disable(title: string) {
    this.items.forEach((item) => {
      if (item.title === title) {
        item.isEnabled = false;
      }

      item.disable(title);
    });
  }
}
