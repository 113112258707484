import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'integrity-test-site-warning',
  templateUrl: './test-site-warning.component.html',
  styleUrls: ['./test-site-warning.component.scss'],
})
export class TestSiteWarningComponent implements OnInit {
  debug = ConfigService.INTEGRITY_DEBUG;
  siteName = environment.name;

  constructor() {}

  ngOnInit(): void {}
}
