import { plainToInstance } from 'class-transformer';
import { FilingTypeKey } from './filing-type-key.enum';

export class FilingType {
  surrogateKey: string;
  label: string;
  key: FilingTypeKey;

  public static createFromJson(json: any): FilingType {
    return plainToInstance(FilingType, json);
  }
}
