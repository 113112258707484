import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthYearDate',
})
export class MonthYearDatePipe implements PipeTransform {
  transform(value: any): any {
    return !!value.month ? value.month.toString().padStart(2,'0') + '/' + value.year : '';
  }
}
