export class ProfileData {
  maxId: string;
  casMaxId: string;
  firstName: string;
  lastName: string;
  email: string;
  groups: string[];

  static fromJson(json: any): ProfileData {
    const profileData = new ProfileData();
    profileData.maxId = json['MAX-ID'];
    profileData.casMaxId = json['CAS-MAX-ID'];
    profileData.groups = json.GroupList.split(',');
    profileData.firstName = json['First-Name'];
    profileData.lastName = json['Last-Name'];

    return profileData;
  }

  public inGroup(group: string): boolean {
    return this.groups.includes(group);
  }
}
