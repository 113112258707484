<ng-template #flashModal>
    <div class="modal-header">
      <h2>Urgent System Announcement</h2>     
      <button
      type="button"
      class="modal-close"
      (click)="close()"
      aria-label="close"
      aria-hidden="true"
      ></button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button 
      type="button" 
      class="btn yellow" 
      data-dismiss="modal" 
      aria-label="close"
      aria-hidden="true" 
      (click)="close()" role="button"
      >OK
     </button>
    </div>
  </ng-template>
