import { Exclude, Expose, plainToInstance, Transform } from 'class-transformer';
import { AgenciesAndGroupsTableRow } from './agencies-and-groups-table-row';

export class GroupsTableRow extends AgenciesAndGroupsTableRow {
  @Expose({ name: 'surrogateKey' })
  groupId: string;
  @Expose({ name: 'name' })
  groupName: string;
  @Expose()
  @Transform(({ value }) => (value ? value.split(',') : []))
  ancestors: string[] = [];
  @Expose()
  @Transform(({ value }) => (value ? parseInt(value, 10) : 0), { toClassOnly: true })
  childrenCount: number;
  @Expose()
  agencySurrogateKey: string;
  
  @Expose()
  parentSurrogateKey: string;

  @Exclude()
  isAgency = false;
  @Exclude()
  isSelectAll = false;
  
  isGroup = true;
  
  constructor() {
    super();
  }
  
  static create(row: any): GroupsTableRow {
    
    const group = plainToInstance(GroupsTableRow, row, {
      excludeExtraneousValues: true,
    });
    
    return group;
  }
  
  get id(): string {
    return this.groupId;
  }

  get name(): string {
    return this.groupName;
  }

  getGroupHierarchy() {
    return [this.groupId, ...this.ancestors];
  }

  getHierarchy() {
    return this.getGroupHierarchy();
  }
  
  linkParent(parent: AgenciesAndGroupsTableRow): void {
    this.agencySurrogateKey = (parent.isAgency) ? parent.id
      : (parent instanceof GroupsTableRow)
        ? parent.agencySurrogateKey : this.agencySurrogateKey;
    this.parent = parent;
  }

  get isTopLevel(): boolean {
    return this.parent.isAgency;
  }
  
  get isSubgroup(): boolean {
    return !this.parent.isAgency;
  }
}
