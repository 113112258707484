import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewerModuleCheckGuardService
  implements CanActivate, CanActivateChild
{
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.check(state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.check(state.url);
  }

  check(url: string) {
    if (
      // Not a review report URL
      !url.startsWith('/reviewer/filing/') &&
      !this.sessionService.hasReviewerMenuToggle()
    ) {
      this.router.navigateByUrl('/loading');
      return false;
    }
    return true;
  }
}

/*
||
      // Or is a review report URL and is not a filer or reviewer
      (url.startsWith('/reviewer/filing/') &&
        !this.sessionService.hasFilerMenuToggle() &&
        !this.sessionService.hasReviewerMenuToggle())
        */
