import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { MenuMode } from '../../shared/models/menu-mode.enum';
import { SessionService } from '../../shared/services/session.service';
import { SubWindowsManagerService } from '../../shared/services/sub-windows-manager.service';
import { MenuService } from '../../shared/services/menu.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TestSiteWarningComponent } from '../test-site-warning/test-site-warning.component';

declare var $: any;

@Component({
  selector: 'integrity-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  protected destroyed$: Subject<boolean> = new Subject();
  filerName: string;
  items: any[];

  modes = MenuMode;

  toggleCount: number;
  showFilerToggle: boolean;
  showReviewerToggle: boolean;
  showAdminToggle: boolean;
  jqueryInitted: boolean = false;
  rebuilding = false;

  constructor(
    private sessionService: SessionService,
    public router: Router,
    public subWindows: SubWindowsManagerService,
    public menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.toggleCount = this.sessionService.menuToggles.length;
    this.showFilerToggle = this.sessionService.hasFilerMenuToggle();
    this.showReviewerToggle = this.sessionService.hasReviewerMenuToggle();
    this.showAdminToggle = this.sessionService.hasAdminMenuToggle();

    this.items = [];

    this.menuService.rebuildMenu$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        setTimeout(() => {
          this.rebuilding = true;
          setTimeout(() => {
            this.rebuilding = false;
          });
        });
      });

    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((e) => {
        this.menuService.show();
      });

    this.menuService.determineMode(this.router.url);
    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        this.menuService.determineMode(this.router.url);

        setTimeout(() => {
          // When the menu is longer than the main content,
          // the main content background won't extend all the way
          // to the bottom of the screen after scrolling.
          // This will manually calculate the height and extend
          // the background.
          // An example of this is when viewing the assets grid
          // and the white background ends immediately after the
          // "I do not have " checkbox.
          this.handleSidebarAndContentHeight();
        });
      });
  }

  handleSidebarAndContentHeight() {
    var content = $('.page-content');
    var sidebar = $('.page-sidebar');
    var body = $('body');
    var height;

    if (
      body.hasClass('page-footer-fixed') === true &&
      body.hasClass('page-sidebar-fixed') === false
    ) {
      var available_height = $(window).height() - $('.footer').height();
      if (content.height() < available_height) {
        content.attr(
          'style',
          'min-height:' + available_height + 'px !important'
        );
      }
    } else {
      if (body.hasClass('page-sidebar-fixed')) {
        //height = _calculateFixedSidebarViewportHeight();
      } else {
        height = sidebar.height() + 20;
      }
      if (height >= content.height()) {
        content.attr('style', 'min-height:' + height + 'px !important');
      }
    }
  }

  ngAfterViewChecked() {
    if (!this.jqueryInitted) {
      this.jqueryInitted = true;

      const _this = this;

      // Open and close top level menu items.
      $('.page-sidebar').on('click', 'li > a', function (this: any, e) {
        if ($(this).next().hasClass('sub-menu') == false) {
          if ($('.btn-navbar').hasClass('collapsed') == false) {
            $('.btn-navbar').click();
          }

          // BEGIN - close all expanded sections that don't include the link that was clicked

          // Submenu containing the clicked menu item
          const outerSubMenu = $(this).parent().parent();

          // Get all menu items
          $('.page-sidebar li > a')
            // Filter down to submenus
            .filter(function (this: any) {
              const subMenu = $(this).next();
              return (
                subMenu.hasClass('sub-menu') &&
                // exclude the submenu containing the clicked item
                subMenu.get(0) != outerSubMenu.get(0)
              );
            })
            .each(function (this: any) {
              var parent = $(this).parent().parent();

              parent
                .children('li.open')
                .children('a')
                .children('.arrow')
                .removeClass('open');
              parent.children('li.open').removeClass('open');

              // Close open submenus
              var sub = $(this).next();
              if (sub.is(':visible')) {
                $('.arrow', $(this)).removeClass('open');
                $(this).parent().removeClass('open');
                sub.slideUp(200, () => {
                  _this.handleSidebarAndContentHeight();
                });
              }
            });
          // END - close all expanded sections that don't include the link that was clicked

          e.preventDefault();
          return;
        }

        var parent = $(this).parent().parent();

        parent
          .children('li.open')
          .children('a')
          .children('.arrow')
          .removeClass('open');
        parent.children('li.open').removeClass('open');

        var sub = $(this).next();
        if (sub.is(':visible')) {
          $('.arrow', $(this)).removeClass('open');
          $(this).parent().removeClass('open');
          sub.slideUp(200, () => {
            _this.handleSidebarAndContentHeight();
          });
        } else {
          $('.arrow', $(this)).addClass('open');
          $(this).parent().addClass('open');
          sub.slideDown(200, () => {
            _this.handleSidebarAndContentHeight();
          });
        }

        e.preventDefault();
      });

      $('.page-sidebar').on('click', '.sidebar-toggler', function (e) {
        var body = $('body');
        var sidebar = $('.page-sidebar');

        if (
          (body.hasClass('page-sidebar-hover-on') &&
            body.hasClass('page-sidebar-fixed')) ||
          sidebar.hasClass('page-sidebar-hovering')
        ) {
          body.removeClass('page-sidebar-hover-on');
          sidebar.css('width', '').hide().show();
          e.stopPropagation();
          return;
        }

        $('.sidebar-search', sidebar).removeClass('open');

        if (body.hasClass('page-sidebar-closed')) {
          body.removeClass('page-sidebar-closed');
          if (body.hasClass('page-sidebar-fixed')) {
            sidebar.css('width', '');
          }
        } else {
          body.addClass('page-sidebar-closed');
        }
      });
    }
  }

  changeMode(url: string) {
    // EFEDS-6981 only execute when the mode is actually changing
    if (this.menuService.menuMode != this.menuService.convertMode(url)) {
      if (this.subWindows.warnAndCloseSubWindows()) {
        this.menuService.determineMode(url);
        this.router.navigateByUrl(url);
      }
    }
  }

  get mode(): MenuMode {
    return this.menuService.mode;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
