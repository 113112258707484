import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  host: { style: 'display: none' },
  selector: 'integrity-reviewer-menu-items',
  templateUrl: './reviewer-menu-items.component.html',
  styleUrls: ['./reviewer-menu-items.component.scss'],
})
export class ReviewerMenuItemsComponent implements OnInit {
  // This template/content stuff is used to move the content outside of the
  // <integrity-reviewer-menu-items> element in the html. This is needed
  // because the css is set up to expect the menu ul/li/a elements directly
  // inside each other.
  // Taken from: https://github.com/angular/angular/issues/18877
  @ViewChild('content', { static: true }) content: TemplateRef<{}>;
  constructor(
    private readonly viewContainer: ViewContainerRef,
    public authorizationService: AuthorizationService
  ) {}

  public window: Window = window;

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.content);
  }
}
