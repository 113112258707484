import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Role } from '../models/role.model';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class FilingService {
  public filingId$ = new BehaviorSubject<string | null>(null);
  public positionId$ = new BehaviorSubject<string | null>(null);
  public secondaryId$ = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  public getUserRolesForFiling(
    userId: string,
    filingId: any
  ): Observable<Role[]> {
    return this.http
      .get<any>(ConfigService.INTEGRITY_SERVICE_GET_USER_ROLES_FOR_FILING, {
        params: {
          sessionUserId: this.sessionService.getMaxUsername(),
          filingId,
        },
        headers: this.sessionService.getNodeHeader(),
      })
      .pipe(
        map((result) => {
          return Role.mapDatabaseResult(result, 'roles');
        })
      );
  }

  public getNonWorkflowUserRolesForFiling(
    userId: string,
    filingId: any
  ): Observable<Role[]> {
    return this.http
      .get<any>(
        ConfigService.INTEGRITY_SERVICE_GET_NON_WORKFLOW_USER_ROLES_FOR_FILING,
        {
          params: {
            sessionUserId: userId,
            filingId,
          },
          headers: this.sessionService.getNodeHeader(),
        }
      )
      .pipe(
        map((result) => {
          return Role.mapDatabaseResult(result, 'roles');
        })
      );
  }
}
