import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { FormDataPersistenceService } from './form-data-persistence.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Injectable({
  providedIn: 'root',
})
export class SessionCheckService {
  public higherSecurityRequired = false;
  public signedUserAgreement: boolean | null = null;
  private lastLoginRecord: any;
  private contactInfoRecord: any;
  public metaInfoRecord: any;
  public isNewYearLogin: boolean | null = null;
  public isDesignee: boolean | null = null;
  public isFiler: boolean | null = null;
  private now: Date = new Date();
  public hasSeenOnboarding = false;

  private IDLE_TIMEOUT_MINUTES = 10;
  public IDLE_TIMEOUT_SECONDS = this.IDLE_TIMEOUT_MINUTES * 60;

  constructor(
    private sessionService: SessionService,
    private formDataPersistenceService: FormDataPersistenceService,
    public idle: Idle
  ) {
    // Set this variable in Local Storage to override the default
    // number of idle seconds before the "extend my session" modal appears
    if (localStorage.IDLE_TIMEOUT_SECONDS) {
      this.IDLE_TIMEOUT_SECONDS = Number(localStorage.IDLE_TIMEOUT_SECONDS);
    }

    // Idle module runs in all windows (even sub windows) so any action in any window resets the idle timer everywhere.
    // The module uses Local Storage for data, which is shared by all windows.
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // clicks, scrolls, keyboard etc

    // Note about idle vs timeout:
    // ng-idle has the concept of both idle and timeout. idle is the number of seconds without user activity,
    // while timeout is the number of seconds after that until a timeout occurs.
    // For integrity, we only really care about "idle" -- which we consider to be the number of seconds until
    // a user receives the "session ending" warning. timeout is set to "1" (the minimum value) to effectively disable it.
    this.idle.setIdle(this.IDLE_TIMEOUT_SECONDS);
    this.idle.setTimeout(1);
  }

  init(): Promise<void> {
    // This has to return a promise because it's used with APP_INITIALIZER

    return Promise.resolve().then(() => {
      if (this.sessionService.isInactive) {
        return;
      } else if (this.sessionService.isLoggedIn()) {
        return this.formDataPersistenceService
          .get('user_login', this.sessionService.getMaxUsername())
          .toPromise()
          .then((data: any) => {
            this.lastLoginRecord = data;
            if (data.status == '0') {
              // This year.
              var thisYear = this.now.getFullYear();

              // Get year of user's last login.
              var lastLoginYear = new Date(
                data.results.data.lastLogin
              ).getFullYear();

              // Redirect to contact info page if user hasn't logged in since last year.
              this.isNewYearLogin = lastLoginYear < thisYear;
            }

            return this.formDataPersistenceService
              .get('1_0_Contact_Info', this.sessionService.getMaxUsername())
              .toPromise();
          })
          .then((ciData: any) => {
            this.contactInfoRecord = ciData;
            if (ciData.status == '50') {
              // Record not found (most likely because higher security is required).
              this.higherSecurityRequired = true;
            } else {
              this.signedUserAgreement =
                ciData.results.data.hasOwnProperty('filer_userAgreement') &&
                ciData.results.data.filer_userAgreement[0] == 'user_agreement';
            }
            return this.formDataPersistenceService
              .get('users_meta_info', this.sessionService.getMaxUsername())
              .toPromise();
          })
          .then((miData) => {
            this.metaInfoRecord = miData;

            if (miData.status == '50') {
              // Record not found (most likely because higher security is required).
              this.higherSecurityRequired = true;
            } else {
              this.isDesignee =
                miData.status == 0 && miData.results.data.type == 'DESIGNEE';
            }
          });
      }
      return;
    });
  }

  recordLogin() {
    var saveData = {
      lastLogin: this.now,
    };

    return this.formDataPersistenceService.save(
      'user_login',
      this.sessionService.getMaxUsername(),
      saveData
    );
  }

  get isAuthBypassed() {
    return this.sessionService.isAuthBypassed();
  }

  /**
   * EFEDS-7509 - check if the user has at least one role in Integrity. If not,
   * redirect to unauthorized.
   */
  userIsAuthorized(): boolean {
    return this.isDesignee || this.sessionService.hasNonDesigneeRole;
  }

  requireOnboarding(): boolean {
    return this.isNewYearLogin || !this.signedUserAgreement;
  }
}
