import {
  Component,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { Event, Router, NavigationEnd } from '@angular/router';
import { ReportUtilitiesService } from '../services/report-utilities.service';
import { FilingService } from '../services/filing.service';
import { Filing } from '../models/filing.model';
import { mergeMap, tap, catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, Observable, Subject } from 'rxjs';
import { MenuItems, MenuItem } from '../models/menu-items.model';
import { IntegrityPersistenceService } from '../services/integrity-persistence.service';
import { SessionCheckService } from '../services/session-check.service';
import { FormDataPersistenceService } from '../services/form-data-persistence.service';
import { HttpClient } from '@angular/common/http';
import { QueryService } from '../services/query.service';
import { SubWindowsManagerService } from '../services/sub-windows-manager.service';

@Component({ template: '' })
export class BaseFilingMenuItemsComponent implements OnDestroy {
  protected destroyed$ = new Subject();
  // This template/content stuff is used to move the content outside of the
  // <integrity-filing-menu-items> element in the html. This is needed
  // because the css is set up to expect the menu ul/li/a elements directly
  // inside each other.
  // Taken from: https://github.com/angular/angular/issues/18877
  @ViewChild('content', { static: true }) content: TemplateRef<{}>;

  filing: Filing;
  filerName: string;
  hasSpouse: boolean = true; // default to yes to show all links
  checkHighwater: boolean = false;
  newHighwaterIndex: boolean = false;

  url$: BehaviorSubject<string | null>;
  menuItems: MenuItems;

  constructor(
    protected viewContainer: ViewContainerRef,
    protected filingService: FilingService,
    protected reportUtilitiesService: ReportUtilitiesService,
    protected router: Router,
    protected integrityPersistenceService: IntegrityPersistenceService,
    protected sessionCheckService: SessionCheckService,
    protected formDataPersistenceService: FormDataPersistenceService,
    protected http: HttpClient,
    protected queryService: QueryService,
    public subWindows: SubWindowsManagerService
  ) {
    this.url$ = new BehaviorSubject(this.router.url);
  }

  init(menuFile: string) {
    this.http
      .get<any>(menuFile)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((menuJson: any) => {
        this.menuItems = MenuItems.createFromJson(menuJson);

        this.filingService.filingId$
          .pipe(takeUntil(this.destroyed$))
          .subscribe((filingId) => {
            if (filingId) {
              this.updateMenu(filingId);
            }

            this.router.events
              .pipe(takeUntil(this.destroyed$))
              .subscribe((event: Event) => {
                if (event instanceof NavigationEnd) {
                  this.url$.next(event.url);

                  if (filingId) {
                    this.updateMenu(filingId);
                  }
                }
              });
          });
      });
  }

  updateMenu(filingId: string) {
    this.pullData(filingId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((results) => {
        if (this.checkHighwater) {
          // Set the highwater mark on the filing object
          // now so that when it's checked in the next step,
          // the menu item will be displayed
          this.updateHighwaterMark();
        }

        this.menuItems.prepare(
          this.checkHighwater,
          !!this.sessionCheckService.isDesignee,
          this.filingService.positionId$.value,
          this.filingService.secondaryId$.value
        );

        this.menuItems.items.forEach((item) => {
          item.flagEnabled = !!item.flagFunction
            ? results[item.flagFunction]
            : false;
          item.items.forEach((subItem) => {
            // OGE-5892 - always show spouse assets link on compare 
            if (subItem.title.startsWith('Spouse') && !this.hasSpouse && !subItem.compareTag) {
              subItem.isEnabled = false;
            }
          });
        });

        this.customizeMenuItems();

        if (this.url$.value) {
          this.menuItems.setActive(this.url$.value);
          this.menuItemActive(this.url$.value);
        }
      });
  }

  customizeMenuItems() {
    // to override
  }

  menuItemActive(url: string) {
    // to override
  }
  
  updateHighwaterMark() {
    this.newHighwaterIndex = false;
    if (this.url$.value && !this.isPrintPage) {
      const index = this.menuItems.getIndex(this.url$.value);
      if (index > this.filing.navHighwaterIndex) {
        this.filing.navHighwaterIndex = index;
        this.newHighwaterIndex = true;
      }
    }
  }

  /**
   * OGE-7895 don't update highwater mark when visiting Printable View
   */
  get isPrintPage(): boolean {
    const url = this.url$.value;
    return !!url && !!url.match(/print/i);
  }

  pullData(filingId: string) {
    return this.reportUtilitiesService.getFiling(filingId).pipe(
      mergeMap((filingRes) => {
        this.filing = filingRes;
        this.menuItems.setFiling(this.filing);

        let observables: { [key: string]: Observable<any> } = {
          filerName: this.reportUtilitiesService
            .getFilerFullName(this.filing)
            .pipe(
              tap((filerName) => {
                this.filerName = filerName;
              })
            ),
        };

        if (this.filing.type == '278') {
          observables['spouse'] = this.reportUtilitiesService
            .getIndicativeData(this.filing)
            .pipe(
              // EFEDS-6560: getIndicativeData() error message written
              // to console.log by the function itself.
              catchError((error) => of(null)),
              tap((results) => {
                if (!!results && results.report_hasSpouse == 'no_spouse') {
                  this.hasSpouse = false;
                }
              })
            );
        }

        observables = { ...observables, ...this.pullAdditionalData() };

        return forkJoin(observables);
      })
    );
  }

  pullAdditionalData(): { [key: string]: Observable<any> } {
    // to override
    return {};
  }

  go(item: MenuItem) {
    this.router.navigateByUrl(item.href);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
