import { Workflow } from './workflow.model';
import _ from 'lodash-es';

export class NomineeWorkflowStep {
  step: number;
  label: string;
  role: string;
  groupSurrogateKey: string | null = null; // should be saved to db as null when not assigned;

  constructor() {}

  static createFromJson(input: any): NomineeWorkflowStep {
    const step = new NomineeWorkflowStep();

    step.step = input.step instanceof Number ? input.step : Number(input.step);
    step.label = input.label;
    step.role = input.role;
    step.groupSurrogateKey =
      input.group_surrogate_key || step.groupSurrogateKey;
    step.groupSurrogateKey = input.groupSurrogateKey || step.groupSurrogateKey;

    return step;
  }

  toJSON(): any {
    const json = _.omit(this, _.isFunction);

    json.group_surrogate_key = json.groupSurrogateKey;
    delete json.groupSurrogateKey;

    return json;
  }
}

export class NomineeWorkflow {
  name: string;
  steps: NomineeWorkflowStep[];

  constructor() {}

  public addStep(step: NomineeWorkflowStep) {
    if (!this.steps) {
      this.steps = [];
    }

    this.steps.push(step);
  }

  static createFromJson(input: any): NomineeWorkflow {
    const workflow = new NomineeWorkflow();

    workflow.name = input.name;
    if (input.steps && Array.isArray(input.steps) && input.steps.length) {
      input.steps.forEach((step) => {
        workflow.addStep(NomineeWorkflowStep.createFromJson(step));
      });
    }

    return workflow;
  }

  toJSON(): any {
    const json = _.omit(this, _.isFunction);

    if (json.steps) {
      json.steps = json.steps.map((step) => {
        return step.toJSON();
      });
    }

    return json;
  }
}

export class NomineeContext {
  assignedToAgencyRouter: string;
  assignedToAgencyNomineeReviewer: string;
  allowAgencyNomineeReviewers: boolean;
  allowAgencyRouters: boolean;
  preclearanceSkippedNomineeReviewer: boolean;
  workflow: NomineeWorkflow;
  sharedWithGroup: string | undefined = undefined;

  static createFromJson(data: any): NomineeContext {
    const nomineeContext = new NomineeContext();
    nomineeContext.assignedToAgencyRouter = data.assignedToAgencyRouter;
    nomineeContext.workflow = NomineeWorkflow.createFromJson(data.workflow);
    nomineeContext.allowAgencyNomineeReviewers =
      data.allowAgencyNomineeReviewers;
    nomineeContext.allowAgencyRouters = data.allowAgencyRouters;
    nomineeContext.preclearanceSkippedNomineeReviewer =
      data.preclearanceSkippedNomineeReviewer;
    nomineeContext.sharedWithGroup = data.sharedWithGroup;

    return nomineeContext;
  }

  toJSON(): any {
    let json = _.omitBy(this, _.isUndefined);
    if (!!json.workflow) {
      json.workflow = this.workflow.toJSON();
    }

    // Remove undefined properties
    json = _.omit(
      json,
      _.filter(_.keys(json), function (key) {
        return _.isUndefined(json[key]);
      })
    );
    return json;
  }
}
