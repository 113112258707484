import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.sessionService.isLoggedIn() && this.sessionService.getCasVal()) {
      const withAuth = request.clone({
        headers: request.headers
          .set('Authorization', this.sessionService.getCasVal()!)
      });
      return next.handle(withAuth);
    }
    else {
      return next.handle(request);
    }
  }
}
