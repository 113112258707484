import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

class ErrorMap {
  static readonly map: Map<string, string> = new Map<string, string>([
    ['update-filing', 'Integrity encountered an error attempting to save this update. Please refresh this browser window and try again'],
    ['reset-filing', 'Integrity encountered an error attempting to reset this filing. Please refresh this browser window and try again'],
    ['version-mismatch', 'This record was recently changed by another Integrity user and the information displayed on your screen is now out of date. Please refresh this browser window to retrieve the latest version of the record.'],
    ['copy-job-description', 'Integrity encountered an error attempting to pre-populate the job description document. Please refresh this browser window and try again.'],
    ['get-filing', 'Integrity encountered an error attempting to retrieve the filing. Please refresh this browser window and try again.'],
    ['get-records-search', 'Integrity encountered an error attempting to retrieve the records search results. Please refresh this browser window and try again.'],
    ['get-find-users', 'Integrity encountered an error attempting to retrieve find users results. Please refresh this browser window and try again.'],
    ['get-reviewer-queue', 'Integrity encountered an error attempting to retrieve the reviewer queue results. Please refresh this browser window and try again.'],
   ]);
}

@Component({
  selector: 'integrity-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Input() title: string;
  @Input() innerError: string | string[];
  isArray: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  set error(error: string | string[]) {
    if (typeof(error) === 'string' && ErrorMap.map.get(error) !== undefined) {
      this.innerError = ErrorMap.map.get(error) ?? error;
    }
    else {
      this.innerError = error;
    }
    this.isArray = Array.isArray(error);
  }
}
