import { Role, RoleKey, RoleType } from './role.model';
import _ from 'lodash-es';

export const enum CommentType {
  // These values are the comment.type values already existing in the database.
  PUBLIC_ANNOTATION = 'ANNOTATION',
  COMMENT = 'COMMENT',
}

export class Comment {
  by: string;
  text: string;
  timestamp: number;
  type: CommentType;
  deletedAt: number; // filing comments only
  deletedBy: string; // filing comments only
  surrogate_key: string; // grid comments only

  static createArrayFromJson(data: any[]): Comment[] {
    const comments: Comment[] = [];
    if (!!data && data.length > 0) {
      data.forEach((item) => {
        const comment = new Comment();
        comment.by = item.by;
        comment.text = item.text;
        comment.timestamp = item.timestamp;
        comment.type = item.type;
        comment.deletedAt = (!!item.deletedAt) ? item.deletedAt : comment.deletedAt;
        comment.deletedBy = (!!item.deletedBy) ? item.deletedBy : comment.deletedBy;
        comment.surrogate_key = (!!item.surrogate_key) ? item.surrogate_key : comment.surrogate_key;

        comments.push(comment);
      });
    }

    return comments;
  }

  // The comments in grids have a different structure from
  // the general filing comments.
  static createArrayFromGridJson(data: any[]): Comment[] {
    const comments: Comment[] = [];
    if (!!data && data.length > 0) {
      data.forEach((item) => {
        const comment = new Comment();
        comment.surrogate_key = item.surrogate_key;
        comment.by = item.comment_user;
        comment.text = item.comment;
        comment.timestamp = Date.parse(item.created_at);
        comment.type =
          item.public === 'true'
            ? CommentType.PUBLIC_ANNOTATION
            : CommentType.COMMENT;
        comments.push(comment);
      });
    }

    return comments;
  }

  toJson(): any {
    const json: any = {};
    for (const key of Object.keys(this)) {
      switch (key) {
        case 'timestamp':
        case 'deletedAt':
          if (this[key] === -999) {
            json[key] = '{{payload.data[0][1].data.server_timestamp}}';
          } else {
            json[key] = this[key];
          }
          break;

        default: {
          if (this[key] !== undefined) {
            json[key] = (this as any)[key];
          }
        }
      }
    }
    return json;
  }

  isPublic(): boolean {
    return this.type === CommentType.PUBLIC_ANNOTATION;
  }

  static canEditComments(roles: Role[]): boolean {
    return roles.reduce((show, role) => {
      return show || this.canRoleEditComments(role);
    }, false);
  }

  private static canRoleEditComments(role: Role): boolean {
    if (role.surrogateKey == RoleKey.Filer || role.type == RoleType.Reviewer) {
      switch (role.surrogateKey) {
        case RoleKey.PPOLead:
        case RoleKey.PPOReviewer:
        case RoleKey.PTTLead:
        case RoleKey.PTTReviewer:
        case RoleKey.WHCOLead:
        case RoleKey.WHCOReviewer:
        case RoleKey.ReadOnlyReviewer:
          return false;
        default:
          return true;
      }
    } else {
      return false;
    }
  }

  static canEditPublicAnnotations(
    roles: Role[],
    currentUserId: string,
    filingUserId: string
  ): boolean {
    // EFEDS-5717 if the user is the filer of this report deny access regardless of other roles/permissions
    if (currentUserId === filingUserId) {
      return false;
    }

    return roles.reduce((show, role) => {
      return show || this.canRoleEditPublicAnnotations(role);
    }, false);
  }

  private static canRoleEditPublicAnnotations(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.Filer:
      case RoleKey.OGENomineeRecordsManager:
      case RoleKey.OGERecordsManager:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
      case RoleKey.ReadOnlyReviewer:
        return false;
      default:
        if (role.type == RoleType.Reviewer) {
          return true;
        } else {
          return false;
        }
    }
  }
}


export class AmendmentComment extends Comment {
  constructor() {
    super();
  }

  static createFromJson(item: any): AmendmentComment|undefined {
    if (item === undefined || item === null || !item.text || !item.text.length) {
      return undefined;
    }
    const comment = new AmendmentComment();
    comment.by = item.by;
    comment.text = item.text;
    comment.timestamp = item.timestamp;
    comment.type = CommentType.PUBLIC_ANNOTATION;

    return comment;
  }

  toJson(): any|undefined {
    const json: any|undefined = undefined;
    if (this.text === undefined || this.text === null || !this.text.length) {
      return json;
    }

    return super.toJson();
  }
}
