import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeStringSpecialCharacters',
})
export class SanitizeStringSpecialCharactersPipe implements PipeTransform {
  transform(
    input: any,
    key: string | null = null,
    configs: { jsonPropertyNames: string[] } = { jsonPropertyNames: [] }
  ): any {
    if (input) {
      if (typeof input === 'object') {
        for (const key in input) {
          input[key] = this.transform(input[key], key, configs);
        }
      } else if (typeof input === 'string') {
        // Replace tabs and non-printing space characters with spaces
        input = input.replace(/[\t]/g, ' ');

        // Replace typographers single and double quotes with regular single and double quotes
        input = input.replace(/[\u2018\u2019]/g, "'");
        input = input.replace(
          /[\u201C\u201D]/g,
          key !== null && configs.jsonPropertyNames.includes(key) ? '\\"' : '"'
        );

        // Replace en and em dashes with hyphens
        input = input.replace(/\u2013|\u2014/g, '-');

        // Remove any embedded javascript
        input = input.replace(
          /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
          ''
        );

        // Replace all other invalid characters with nothing (final whitelist)
        input = input.replace(
          /[^a-zA-Z0-9 ,#@$%\^\*&+=|'";:<>`~\.\?\!\-\_\(\)\[\]\{\}\\\n\/]/gi,
          ''
        );

        // Strip out HTML tags
        input = input.replace(/(<[^ >]>)|(<[^ ]([^>]+)>)/gi, '');
      }
    }
    return input;
  }
}
