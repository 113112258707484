import {
  NgModule,
  APP_INITIALIZER,
  InjectionToken,
  ErrorHandler,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { SessionCheckService } from '../shared/services/session-check.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormDataPersistenceService } from '../shared/services/form-data-persistence.service';
import { SessionService } from '../shared/services/session.service';
import { GlobalErrorHandler } from './error-handler/global-error-handler';
import { TriggerErrorComponent } from './trigger-error/trigger-error.component';
import { TestSiteWarningModule } from './test-site-warning/test-site-warning.module';

const ConfigDeps = new InjectionToken<(() => Function)[]>('configDeps');

export function initEverything(
  sessionService: SessionService,
  formDataPersistenceService: FormDataPersistenceService,
  sessionCheckService: SessionCheckService
) {
  return (): Promise<any> => {
    return sessionService
      .init()
      .then(() => {
        if (sessionService.isLoggedIn()) {
          return formDataPersistenceService.init();
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        return sessionCheckService.init();
      });
  };
}

export function initializeSessionCheckService(
  sessionCheckService: SessionCheckService
) {
  return (): Promise<any> => {
    return sessionCheckService.init();
  };
}

export function configurationFactory(
  sessionService: SessionService,
  configDeps: (() => Function)[]
): () => Promise<any> {
  debugger;
  return (): Promise<any> => {
    return sessionService.init().then(function () {
      debugger;
      // Return resolved Promise when dependant functions are resolved
      return Promise.all(configDeps.map((dep) => dep())); // configDeps received from the outside world
    });
  };
}

@NgModule({
  declarations: [LoginLayoutComponent, TriggerErrorComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    TestSiteWarningModule,
  ],
  exports: [],
  providers: [
    SessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initEverything,
      deps: [SessionService, FormDataPersistenceService, SessionCheckService],
      multi: true,
    },
    /*{
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      multi: true,
      // ConfigDeps is now a dependency for configurationFactory
      deps: [SessionService, ConfigDeps],
    },
    {
      provide: ConfigDeps,
      // Use a factory that return an array of dependant functions to be executed
      useFactory: (sessionCheckService: SessionCheckService) => {
        debugger;
        // Easy to add or remove dependencies
        return [initializeSessionCheckService];
      },
      deps: [SessionCheckService],
    },*/
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {}
