<div class="width640-warning onboarding" *ngIf="isLoggedIn">
  <div class="logo-header">
    <img
      src="assets/img/logo-oge.png"
      width="199"
      height="86"
      alt="United States Office of Government Ethics, Preventing Conflicts of Interest in the Executive Branch"
    />
  </div>
  <div class="welcome-subheader">
    <p>EDITING DISABLED</p>
  </div>
  <div class="onboard-body-wide">
    <p class="intro">
      Due to the complexity of this application, a device with a larger viewing
      area is required (e.g. tablet, desktop or laptop).
    </p>
    <p class="intro">
      If you are trying to <strong>print</strong> this screen, please ensure
      that the paper orientation is set to <strong>landscape</strong>.
    </p>
    <hr />
    <p class="outro">
      If you have any issues or questions regarding your Integrity account,
      please contact the Office of Government Ethics.
    </p>
  </div>
</div>
<span [ngClass]="{ 'show-content': isLoggedIn }">
  <router-outlet></router-outlet>
</span>
