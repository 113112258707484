import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionCheckService } from './session-check.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class RequireOnboardingGuardService {
  constructor(
    private sessionService: SessionService,
    private sessionCheckService: SessionCheckService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    return this.check(state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    return this.check(state.url);
  }

  check(url: string): boolean | Promise<boolean> {
    if (
      this.sessionCheckService.hasSeenOnboarding &&
      this.requireOnboarding()
    ) {
      return this.sessionCheckService.init().then(() => {
        return this.redirect(url);
      });
    } else {
      return this.redirect(url);
    }
  }

  redirect(url: string): boolean {
    if (this.sessionService.isLoggedIn()) {
      // Don't redirect to onboarding if we're already headed there
      if (url == '/filer/onboarding') {
        return true;
      }

      if (this.requireOnboarding()) {
        this.router.navigate(['/filer/onboarding']);
        return false;
      }

      return true;
    }

    this.router.navigate(['/unauthorized']);
    return false;
  }

  requireOnboarding(): boolean {
    return this.sessionCheckService.requireOnboarding();
  }
}
