export enum ReportStatus {
  NotStarted = 'Not Started',
  Draft = 'Draft, Pre-Review',
  AgencyReview = 'Report, Under Agency Review',
  AgencyCertified = 'Report, Agency Certified',
  OGECertified = 'Report, OGE Certified',
  DraftAgencyReview = 'Draft, Under Agency Review',
  ClosedWithoutOGECertification = 'Report, Closed w/o OGE Certification',
  ClosedWithoutAgencyCertification = 'Report, Closed w/o Agency Certification',
  UnderOGEReview = 'Report, Under OGE Review',
  UnderReview = 'Under Review',
  DraftUnderReview = 'Draft, Under Review',
  DraftPendingRelease = 'Draft, Pending Release',
  DraftPreCleared = 'Draft, Pre-Cleared',
  DraftPreReview = 'Draft, Pre-Review',
  DataImported = 'Data Imported',
}

export class Status {
  status: ReportStatus;
  timeStamp: number;
  by: string;
  workflowStep: number;
  fullName: string;
  pulledForward: boolean;

  constructor(status?: string, init?: Partial<Status>) {
    if (status) {
      this.convertReportStatusEnum(status);
    }
    Object.assign(this, init);
  }

  toJson(): any {
    const json: any = {};
    for (const key of Object.keys(this)) {
      switch (key) {
        case 'timeStamp':
          if (this.timeStamp === -999) {
            json.timestamp = '{{payload.data[0][1].data.server_timestamp}}';
          } else {
            json.timestamp = this[key];
          }
          break;
        case 'fullName':
          // skip
          break;
        default: {
          if (this[key] != undefined) {
            json[key] = (this as any)[key];
          }
        }
      }
    }

    return json;
  }

  public get isTerminal(): boolean {
    return (this.status === ReportStatus.AgencyCertified ||
        this.status === ReportStatus.OGECertified ||
        this.status === ReportStatus.ClosedWithoutAgencyCertification ||
        this.status === ReportStatus.ClosedWithoutOGECertification
    );
  }

  private convertReportStatusEnum(status: string) {
    for (const entry of Object.entries(ReportStatus)) {
      if (entry[1] === status) {
        this.status = entry[1];
      }
    }
  }
}
