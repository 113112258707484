/* For Value Service option value lists such as from option-values.xlsx */

export class ValueOption {
  value: string | number;
  display: string;
  gridDisplay: string | undefined = undefined;

  constructor(
    value: string,
    display: string,
    gridDisplay: string | undefined = undefined
  ) {
    this.value = value;
    this.display = display;
    this.gridDisplay = gridDisplay;
  }

  /**
   * Create a blank option - to insert at the top of a list, use
   * myList.unshift(ValueOption::addBlank(''));
   */
  public static addBlank(label: string = ''): ValueOption {
    return new ValueOption('', label);
  }
}
