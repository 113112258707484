import {
  instanceToPlain,
  Exclude,
  plainToInstance,
  plainToClassFromExist,
  Transform,
  TransformationType,
  Type,
} from 'class-transformer';
import { BaseGroup } from './base-group.model';
import { FilingTypeKey } from './filing-type-key.enum';
import { AgencyConfigs, OgeOversightConfigs } from './group-configs.model';
import {
  OGEOversightStaff,
  PPOWHCOStaff,
  RegularStaff,
} from './group-staff.model';
import { AgencyType } from './role.model';

export class Agency extends BaseGroup {
  isAgency: boolean = true;
  shortName: string;
  agencyType: AgencyType = AgencyType.Regular;
  administrationIdentifier: string;

  @Transform(({ value, obj, type }) => {
    if (!value) return value;

    switch (type) {
      case TransformationType.PLAIN_TO_CLASS:
        const agencyType: string = obj.agencyType;
        switch (agencyType) {
          case 'OGE-OVERSIGHT':
            return plainToInstance(OgeOversightConfigs, obj.configs);
          default:
            return plainToInstance(AgencyConfigs, obj.configs);
        }
      case TransformationType.CLASS_TO_PLAIN:
        return instanceToPlain(value);
      default:
        return value;
    }
  })
  configs: AgencyConfigs | OgeOversightConfigs = new AgencyConfigs();

  workflowTypes: Map<FilingTypeKey, string | null>;

  constructor() {
    super();
    this.workflowTypes = new Map<FilingTypeKey, string | null>();
    this.workflowTypes.set(FilingTypeKey.General, null);
    this.workflowTypes.set(FilingTypeKey.Confirmed, null);
  }

  setAgencyType(agencyType: AgencyType) {
    this.agencyType = agencyType;

    switch (agencyType) {
      case AgencyType.OGEOversight:
        this.configs = new OgeOversightConfigs();
        this.staff = new OGEOversightStaff();
        break;
      case AgencyType.PTT:
      case AgencyType.PPO:
      case AgencyType.WHCO:
        this.configs = new AgencyConfigs();
        this.staff = new PPOWHCOStaff();
        break;
      default:
        this.configs = new AgencyConfigs();
        this.staff = new RegularStaff();
        break;
    }
  }

  isNomineeAgency() {
    switch (this.agencyType) {
      case AgencyType.PTT:
      case AgencyType.PPO:
      case AgencyType.WHCO:
        return true;
      default:
        return false;
    }
  }

  loadFromJson(json: any): void {
    plainToClassFromExist(this, json);

    // EFEDS-5255 - existing agencies should be set to no whereas new ones to yes.
    if (json.configs && !json.configs.cascadeAllRoles) {
      this.configs.cascadeAllRoles = false;
    }
  }

  static createFromJson(json: any): Agency {
    const agency = new Agency();
    agency.loadFromJson(json);
    return agency;
  }

  public toJSON(): any {
    const plain = instanceToPlain(this);

    // Clean up the plain object to match the fields v1 used when saving to the database
    switch (this.agencyType) {
      case AgencyType.OGEOversight:
      case AgencyType.PTT:
      case AgencyType.PPO:
      case AgencyType.WHCO:
        delete plain.configs.showEmailHelpLink;
        break;
      default:
        break;
    }

    // Delete any empty staff arrays
    // EFEDS-7261 If empty arrays are left in the staff object,
    // they will cause user_group_role_view to have values of user_id='[]'
    if (plain.staff) {
      const keys = Object.keys(plain.staff);
      keys.forEach((key) => {
        if (Array.isArray(plain.staff[key]) && !plain.staff[key].length) {
          delete plain.staff[key];
        }
      });
    }

    return plain;
  }
}
