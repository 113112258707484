import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { Observable, interval, Subscription, Subject } from 'rxjs';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryService } from '../services/query.service';
import { SubWindowsManagerService } from '../services/sub-windows-manager.service';
import { SessionService } from '../services/session.service';

const MINUTE: number = 60000; // Milliseconds

@Component({
  selector: 'integrity-flash-message-checker',
  templateUrl: './flash-message-checker.component.html',
  styleUrls: ['./flash-message-checker.component.scss'],
})
export class FlashMessageCheckerComponent implements OnInit, OnDestroy {
  @ViewChild('flashModal') flashModal: ElementRef;

  modal: any;
  message: string;
  flashCheck: Subscription;
  shown: boolean = false;
  opened: boolean = false;
  isMainWindow: boolean;
  protected destroyed$: Subject<boolean> = new Subject();

  constructor(
    private modalService: NgbModal,
    private queryService: QueryService,
    private subWindows: SubWindowsManagerService,
    private sessionService: SessionService,
    private ngZone: NgZone // Since we're using global window functions
  ) {}

  ngOnInit(): void {
    if (!this.sessionService.isLoggedIn()) {
      return;
    }

    this.isMainWindow = !window.opener;

    if (this.isMainWindow) {
      window.closeFlashModals = () => {
        this.ngZone.run(() => {
          this.closeFlashModals();
        });
      };
      this.flashMessageCheck();
    } else {
      window.openFlashModal = (message: string) => {
        this.ngZone.run(() => {
          this.openFlashModal(message);
        });
      };
    }

    window.closeFlashModal = () => {
      this.ngZone.run(() => {
        this.closeFlashModal();
      });
    };
  }

  flashMessageCheck(): void {
    this.flashCheck = interval(MINUTE / 2)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => {
        this.queryService
          .getFlashMessage()
          .pipe(takeUntil(this.destroyed$))
          .subscribe((message) => {
            if (message !== undefined && this.message != message) {
              this.message = message;
              this.shown = false;
            }
            if (this.message.length && !this.shown) {
              this.openFlashModals();
            }
          });
      });
  }

  openFlashModals() {
    this.openFlashModal(this.message);

    // Show modal in each subwindow
    this.subWindows.getOpenSubWindows().forEach((subwindow) => {
      subwindow.openFlashModal(this.message);
    });
  }

  closeFlashModals() {
    this.closeFlashModal();

    this.subWindows.getOpenSubWindows().forEach((subwindow) => {
      subwindow.closeFlashModal();
    });
  }

  openFlashModal(message: string): void {
    if (!this.sessionService.isLoggedIn || this.opened) {
      return;
    }

    if (!this.isMainWindow) {
      this.message = message;
    }

    this.modal = this.modalService.open(this.flashModal, {
      ariaLabelledBy: 'modal-basic-title',
      scrollable: false,
      backdrop: 'static',
      centered: true,
    });
    this.shown = true;
    this.opened = true;

    this.modal.result
      .then((result) => {
        //debugger;
      })
      .catch((error) => {
        //debugger;
      });
  }

  /**
   * If the main window or any sub window closes the announcement modal,
   * send the request to the parent to close itself and all subwindows.
   */
  close(): void {
    if (this.isMainWindow) {
      this.closeFlashModals();
    } else if (window.opener != null) {
      window.opener.closeFlashModals();
    }
  }

  closeFlashModal(): void {
    this.modal.close();
    this.opened = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
