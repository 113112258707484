import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableRow } from './table-row.model';
import { nonenumerable } from '../decorators/enumerable';
import { Subject } from 'rxjs';
import _ from 'lodash';

export interface MonthYear {
  month?: number | null;
  year?: number | null;
}

export abstract class TableData extends TableRow {
  // version property should not be saved to node but is needed for the gridDataPersistenceService update method; 
  // it is cleaned out in that service after it is checked
  static NOT_PASSED: Array<string> = [
    'primaryProperty',
    'checkboxDisabled',
    'checked',
    'parentSequenceId',
    'prepopulatedCreatedAt',
    'COLLAPSED',
    'timestamp_created_at',
    'initialSort',
    'comment_cnt',
    'ENDNOTEPrevState',
    'ENDNOTEUPDATED',
  ];
  
  ID: string;
  sequenceId = '';
  COLLAPSED = true;
  prepopulated?: string;

  version?: string;
  @nonenumerable
  formData = new Map<string, FormData>();
  
  created_at?: number;
  initialSort: any;

  parentSequenceId?: string;
  PARENTASSETID?: string;
  PARENTASSETNAME?: string; 
  primaryProperty: string;
  checked = false;
  checkboxDisabled = false;
  prepopulatedCreatedAt?: number; 
  timestamp_created_at?: number;

  @nonenumerable
  protected formOrder: Map<string, string>;

  protected constructor(data: any) {
    super();
    if (data !== null) {
      const temp =
        typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
      this.ID = temp.ID ?? '';
      this.sequenceId = temp.sequenceId;
      // Parent asset name at the time the item was last saved - do not rely on this value for displaying current filing data. 
      this.PARENTASSETNAME = temp.PARENTASSETNAME ?? '';
      this.PARENTASSETID = (!!temp.PARENTASSETID) ? temp.PARENTASSETID : this.PARENTASSETID;
      this.created_at = (!!temp.created_at) ? temp.created_at : this.created_at;
      this.timestamp_created_at = (!!temp.timestamp) ? temp.timestamp : this.timestamp_created_at;
      this.prepopulatedCreatedAt = (!!temp.prepopulatedCreatedAt) ? temp.prepopulatedCreatedAt : this.prepopulatedCreatedAt;
      this.prepopulated = (!!temp.prepopulated) ? temp.prepopulated : this.prepopulated;
    }
  }

  initializeFormData() {
    this.formOrder.forEach((value, key) => {
      this.formData.set(key, this[value]);
    });
  }
  
  public fillFormData(): Map<string, FormData> {
    for (const data of this.formData.values()) {
      if (this[data.property] !== null) {
        data.formControl.setValue(this[data.property]);
      }
    }

    return this.formData;
  }

  get parentDisplay(): string {
    return !!this.parentSequenceId
      ? this.parentSequenceId + ' ' + this.PARENTASSETNAME
      : '';
  }

  /**
   * Set the parent asset name for assets that come from the
   * wizard grid.
   */
  setParent(description: string|undefined): void {
    if (!!this.PARENTASSETNAME || !description) {
      return;
    }

    this.PARENTASSETNAME = description;
  }

  /**
   * Asset models override with their field "DESCRIPTION"
   */
  get itemDescription(): string|undefined {
    return this.PARENTASSETNAME;
  }
  
  /**
   * Omit properties that are only used for rendering the UI
   * OGE-7444
   */
  propToJson(json: any, key: string, NOT_PASSED: Array<string> = []): any {
    NOT_PASSED = [...TableData.NOT_PASSED, ...NOT_PASSED];
    if (key !== 'NOT_PASSED' && !NOT_PASSED.includes(key) && this[key] !== undefined) {
      json[key] = (this as any)[key];
    }
    return json;
  }
}

export abstract class CommentTableData extends TableData {
  comment_cnt: number;
  
  protected constructor(data: any) {
    super(data);
    if (data !== null) {
      this.comment_cnt = data.comment_cnt;
    }
  }
}

export abstract class EndNoteTableData extends CommentTableData {
  ENDNOTE: string;
  ENDNOTEPrevState: string;
  ENDNOTEUPDATED: boolean;

  protected constructor(data: any) {
    super(data);
    if (data !== null) {
      const temp =
        typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
      this.ENDNOTE = temp.ENDNOTE ?? '';
      this.ENDNOTEPrevState = temp.ENDNOTEPrevState ?? '';
      this.ENDNOTEUPDATED = temp.ENDNOTEUPDATED === 'true';
    }
  }
  
  get endnote(): FormData {
    return (
      this.formData.get('ENDNOTE') ??
      new FormData({
        title: 'OPTIONAL ENDNOTE',
        property: 'ENDNOTE',
        type: 'ENDNOTE',
        formControl: new UntypedFormControl(''),
        tooltip: true,
      })
    );
  }
}

export abstract class FromToTableData extends EndNoteTableData {
  FROMMONTH: string;
  FROMYEAR: string;
  TOMONTH: string;
  TOYEAR: string;
  PRESENT?: boolean;

  protected constructor(data: any) {
    super(data);
    if (data !== null) {
      const temp =
        typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
      this.FROMMONTH = temp.FROMMONTH ?? '';
      this.FROMYEAR = temp.FROMYEAR ?? '';
      this.TOMONTH = temp.TOMONTH ?? '';
      this.TOYEAR = temp.TOYEAR ?? '';
      if (temp.PRESENT !== undefined) {
        this.PRESENT =
          typeof temp.PRESENT == 'boolean'
            ? temp.PRESENT
            : temp.PRESENT == 'true';
      }
    }
  }

  initializeFormData() {
    super.initializeFormData();
    this.toMonth.hidden = false;
    this.toYear.hidden = false;
  }

  get FROM(): string {
    return this.FROMMONTH + '/' + this.FROMYEAR;
  }

  get TO(): string {
    if (this.PRESENT === true) {
      return 'Present';
    }
    return this.TOMONTH + '/' + this.TOYEAR;
  }

  get toYear(): FormData {
    return (
      this.formData.get('TOYEAR') ||
      new FormData({
        title: 'SELECT YEAR',
        property: 'TOYEAR',
        type: 'year',
        default: '',
        defaultOptionLabel: 'Choose a Year...',
        formControl: new UntypedFormControl(''),
        typeAhead: false,
        tooltip: false,
        hideLabel: true,
      })
    );
  }

  get toMonth(): FormData {
    return (
      this.formData.get('TOMONTH') ||
      new FormData({
        title: 'SELECT POSITION END DATE',
        property: 'TOMONTH',
        type: 'month',
        default: '',
        defaultOptionLabel: 'Choose a Month...',
        formControl: new UntypedFormControl(''),
        typeAhead: false,
        tooltip: true,
      })
    );
  }

  get fromYear(): FormData {
    return (
      this.formData.get('FROMYEAR') ||
      new FormData({
        title: 'SELECT YEAR',
        property: 'FROMYEAR',
        type: 'year',
        default: '',
        defaultOptionLabel: 'Choose a Year...',
        formControl: new UntypedFormControl(''),
        typeAhead: false,
        tooltip: false,
        hideLabel: true,
      })
    );
  }

  get fromMonth(): FormData {
    return (
      this.formData.get('FROMMONTH') ||
      new FormData({
        title: 'SELECT POSITION START DATE',
        property: 'FROMMONTH',
        type: 'month',
        default: '',
        defaultOptionLabel: 'Choose a Month...',
        formControl: new UntypedFormControl(''),
        typeAhead: false,
        tooltip: true,
      })
    );
  }

  get present(): FormData {
    return (
      this.formData.get('PRESENT') ??
      new FormData({
        title: 'PRESENT',
        property: 'PRESENT',
        type: 'boolean',
        formControl: new UntypedFormControl(false),
        typeAhead: false,
        additionalText: 'Present',
        tooltip: true,
        onSelect: this.presentSelect,
        isCheckbox: true,
      })
    );
  }

  presentSelect = (value: boolean | string): string[] => {
    if (typeof value === 'string') {
      this.toMonth.hidden = value === 'true';
      this.toYear.hidden = value === 'true';
    } else {
      this.toMonth.hidden = value;
      this.toYear.hidden = value;
    }
    return ['TOYEAR', 'TOMONTH'];
  }
}

export class FormData {

  constructor(data: any) {
    this.title = data.title;
    this.property = data.property;
    this.type = data.type;
    this.default = data.default;
    this.defaultOptionLabel = data.defaultOptionLabel ?? '';
    this.formControl = data.formControl;
    this.additionalText = data.additionalText;
    this.titleStyle = data.titleStyle;
    this.typeAhead = data.typeAhead;
    this.tooltip = data.tooltip;
    this.tooltipType = data.tooltipType;
    this.hidden = data.hidden ?? false;
    this.hideLabel = data.hideLabel ?? false;
    this.fauxLabel = data.fauxLabel ?? false;
    this.isCheckbox = data.isCheckbox ?? false;
    this.placeholder = data.placeholder;
    this.onSelect = data.onSelect ?? this.onSelect;
    this.warning$ = data.warning$;
    this.startDate = data.startDate ?? this.startDate;
    this.setupStartDate();
  }
  static dateChanged$: Subject<string> = new Subject();
  title: string;
  titleStyle?: any;
  property: string;
  type: string;
  default?: string | null; // ngbDate fields must be reset to null not empty string to be valid
  defaultOptionLabel: string; // select option label for default '' option value
  formControl: UntypedFormControl | UntypedFormGroup;
  additionalText?: string;
  typeAhead?: boolean;
  tooltip?: boolean;
  tooltipType?: string;
  hidden: boolean;
  hideLabel: boolean;
  fauxLabel?: boolean;
  isCheckbox: boolean;
  placeholder?: string;
  warning$?: Subject<string>; // Send a warning message to a grid form input.
  startDate: MonthYear; // Initial calendar picker month and year

  onSelect(
    value: any,
    data: FormData | undefined = undefined,
    option: string | undefined = undefined
  ): string[] {
    return [];
  }
  
  setupStartDate(): void {
    if (this.type === 'date') {
      this.formControl.valueChanges.subscribe((value: string) => {
        FormData.dateChanged$.next(value);
        this.setStartDate();
      });
    }
  }

  /**
   * JDG: I think we don't want to every clear the startDate when there
   * is no value in the field - I think the whole point is to keep the
   * most recent month/year until the field has any new date value.
   * 
   * EFEDS-7238
   */
  setStartDate(): void {
    FormData.dateChanged$.subscribe((value: string) => {
      if (!!value) {
        const date = new Date(value);
        if (date instanceof Date) {
          this.startDate = { month: date.getMonth() + 1, year: date.getFullYear() };
        }
      }
    });
  }
}
