import _ from 'lodash-es';

export class Signature {
  active: boolean;
  timestamp: number;
  by: string;
  step: number|undefined = undefined; // not set when report is closed
  extension?: string;

  // not passed to Node
  fullName: string;

  public constructor(init?: Partial<Signature>) {
    Object.assign(this, init);
  }

  toJSON(): any {
    let json: any = {};
    for (const key of Object.keys(this)) {
      switch (key) {
        case 'timestamp':
          if (this.timestamp === -999) {
            json.timestamp = '{{payload.data[0][1].data.server_timestamp}}';
          } else {
            json.timestamp = this[key];
          }
          break;

        default: {
          if (this[key] != undefined) {
            json[key] = (this as any)[key];
          }
        }
      }
    }

    json = _.omit(json, ['fullName']);
    return json;
  }

  static createMapFromJson(input: any): Map<string, Signature[]> {
    const signatures: Map<string, Signature[]> = new Map();

    if (input) {
      Object.keys(input).forEach((key) => {
        if (input[key] && Array.isArray(input[key]) && input[key].length) {
          const list: Signature[] = [];
          input[key].forEach((signature) => {
            list.push(Signature.createFromJson(signature));
          });

          signatures.set(key, list);
        }
      });
    }

    return signatures;
  }

  static createFromJson(input: any): Signature {
    return new Signature(input);
  }
}
