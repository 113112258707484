import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'integrity-filer',
  templateUrl: './filer.component.html',
  styleUrls: ['./filer.component.scss'],
})
export class FilerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
