import { Injectable } from '@angular/core';
import { CacheFactory, Cache } from 'cachefactory';
import { QueryService } from './query.service';
import { forkJoin, Observable, of } from 'rxjs';
import {map, mergeMap, share, tap} from 'rxjs/operators';
import { FormDataPersistenceService } from './form-data-persistence.service';
import { ContactInfo } from '../models/contact-info.model';
import { User } from '../../modules/admin/user-provision-status/user-provision-status.models';

const cacheFactory = new CacheFactory();

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private queryService: QueryService,
    private formDataPersistenceService: FormDataPersistenceService
  ) {
    if (!cacheFactory.exists('userIdToFullName')) {
      this.cache = cacheFactory.createCache('userIdToFullName');
    } else {
      this.cache = cacheFactory.get('userIdToFullName');
    }

    if (!cacheFactory.exists('userIdToFullNameByTimestamp')) {
      this.cacheByTimestamp = cacheFactory.createCache(
        'userIdToFullNameByTimestamp'
      );
    } else {
      this.cache = cacheFactory.get('userIdToFullNameByTimestamp');
    }
  }
  cache: Cache;
  cacheByTimestamp: Cache;
  getFullNameInFlight = {};

  public getFullNames(
    userIds: string[]
  ): Observable<{ [userId: string]: string }> {
    let processing: Observable<any>[] = [];
    userIds.forEach((userId) => {
      let cached = this.cache.get(userId);
      if (!cached) {
        processing.push(
          this.queryService.getMinimalContactInfo(userId).pipe(
            map((result) => {
              if (result) {
                // Extract the full name -- TODO: this should be changed to just return form_1_0_contact_info.full_name
                let name = UserService.contactInfoDocToFullName(result);
                this.cache.put(result.surrogate_key, name);
                return name;
              }
              // bad
              return null;
            })
          )
        );
      } else {
        processing.push(of(cached));
      }
    });

    return forkJoin(processing).pipe(
      map((results) => {
        const names: any = {};
        userIds.forEach((userId) => {
          names[userId] = this.cache.get(userId);
        });

        return names;
      })
    );
  }

  public getFullName(userId): Observable<string> {
    let cached = this.cache.get(userId);
    if (cached) {
      return of(cached);
    }

    // If a request for this user id is already pending,
    // return the pending observable instead of creating a new one
    var inFlight = this.getFullNameInFlight[userId];
    if (inFlight) {
      return inFlight;
    }

    this.getFullNameInFlight[userId] = this.queryService
      .getMinimalContactInfo(userId)
      .pipe(
        map((result) => {
          if (result) {
            // Extract the full name -- TODO: this should be changed to just return form_1_0_contact_info.full_name
            let name = UserService.contactInfoDocToFullName(result);
            this.getFullNameInFlight[userId] = null;
            this.cache.put(result.surrogate_key, name);
            return name;
          }
          // bad
          return null;
        })
      )
      .pipe(share());

    return this.getFullNameInFlight[userId];
  }

  static contactInfoDocToFullName(data: any): string {
    return UserService.namePartsToFullname(
      data.filer_firstName,
      data.filer_middleInitial,
      data.filer_lastName
    );
  }

  static namePartsToFullname(
    first: string | null,
    middle: string | null,
    last: string | null
  ): string {
    let name = '';
    if (last !== null && last !== '') {
      name = last;
    }
    if (first !== null && first !== '') {
      if (last !== null && last !== '') {
        name += ', ';
      }

      name += first;

      if (middle != null && middle !== '') {
        name += ' ' + middle;
      }
    }

    return name;
  }

  public getUsersMetaInfo(userId: string) {
    return this.formDataPersistenceService.get('users_meta_info', userId);
  }

  /**
   * Insert a new version where the user_id column is set to the session user
   * and then set the latest to record_status 2.
   * OGE-8518
   */
  public removeUser(userId: string) {
    const me = this;
    return this.getUsersMetaInfo(userId)
      .pipe(
        mergeMap((result) => {
          if (!!result && !!result.results && !!result.results.data) {
            return me.saveUsersMetaInfo(userId, result.results.data);
          }
          throw new Error('Failed to save update to remove designee');
        })
      )
      .pipe(
        mergeMap(() => {
          return me.deleteUsersMetaInfo(userId);
        })
      );
  }

  public deleteUsersMetaInfo(userId: string) {
    return this.formDataPersistenceService.delete('users_meta_info', userId);
  }

  public saveUsersMetaInfo(userId: string, umi: object) {
    return this.formDataPersistenceService.save('users_meta_info', userId, umi);
  }

  public getFullNameByTimestamp(userId: string, timestamp: number) {
    if (!timestamp) {
      return of('');
    }
    const cacheKey = `${userId}-${timestamp}`;
    let fullName = this.cacheByTimestamp.get(cacheKey);

    if (fullName) {
      return of(fullName);
    }

    return this.queryService.getContactInfoByTimestamp(userId, timestamp).pipe(
      map((ci: any) => {
        if (ci) {
          fullName = UserService.contactInfoDocToFullName(ci.data);
          this.cacheByTimestamp.put(cacheKey, fullName);
          return fullName;
        }
        // bad
        return null;
      })
    );
  }

  getNamesSorted(userIds: string[]) {
    if (!userIds.length) {
      return of([]);
    }
    return this.getFullNames(userIds).pipe(
      map((names) => {
        return Object.keys(names)
          .map((key) => ({
            id: key,
            name: names[key],
          }))
          .sort((a, b) => {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
      })
    );
  }

  public cacheGroupUserNames(groupId: string): Observable<any> {
    return this.queryService.getGroupAndAncestorUserNames(groupId).pipe(
      tap((users) => {
        users.forEach((user) => {
          let cached = this.cache.get(user.surrogate_key);
          if (!cached) {
            this.cache.put(user.surrogate_key, user.full_name);
          }
        });
      })
    );
  }

  public cacheNameFromContactInfo(ci: ContactInfo): string {
    const cached = this.cache.get(ci.id);
    if (cached) {
      return cached;
    }

    const name = UserService.namePartsToFullname(
      ci.firstName,
      ci.middleInitial ?? null,
      ci.lastName
    );

    this.cache.put(ci.id, name);

    return name;
  }

  public getContactInfo(userId: string): Observable<ContactInfo> {
    return this.formDataPersistenceService.get('1_0_Contact_Info', userId).pipe(
      map((result) => {
        const ci = ContactInfo.createFromDbRow(result.results);
        this.cacheNameFromContactInfo(ci);
        return ci;
      })
    );
  }
}
