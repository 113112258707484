import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FilerComponent } from './modules/filer/filer.component';
import { LoginLayoutComponent } from './core/layout/login-layout/login-layout.component';
import { RequireLoginGuardService } from './shared/services/require-login-guard.service';
import { RequireOnboardingGuardService } from './shared/services/require-onboarding-guard.service';
import { RedirectLoginGuardService } from './shared/services/redirect-login-guard.service';
import { FilerModuleCheckGuardService } from './shared/services/filer-module-check-guard.service';
import { ReviewerModuleCheckGuardService } from './shared/services/reviewer-module-check-guard.service';
import { AdminModuleCheckGuardService } from './shared/services/admin-module-check-guard.service';
import { RoleGuardService } from './shared/services/role-guard.service';
import { MenuMode } from './shared/models/menu-mode.enum';
import { ReviewerComponent } from './modules/reviewer/reviewer.component';
import { AdminComponent } from './modules/admin/admin.component';
import { TriggerErrorComponent } from './core/trigger-error/trigger-error.component';
import { MaintenanceLoginGuardService } from './shared/services/maintenance-login-guard.service';

const routes: Routes = [
  {
    path: 'filer',
    data: { menuMode: MenuMode.Filer },
    component: FilerComponent,
    canActivate: [
      RequireLoginGuardService,
      MaintenanceLoginGuardService,
      RequireOnboardingGuardService,
      FilerModuleCheckGuardService,
    ],
    loadChildren: () =>
      import('./modules/filer/filer.module').then((m) => m.FilerModule),
  },
  {
    path: 'reviewer',
    data: { menuMode: MenuMode.Reviewer },
    component: ReviewerComponent,
    canActivate: [
      RequireLoginGuardService,
      MaintenanceLoginGuardService,
      RequireOnboardingGuardService,
      ReviewerModuleCheckGuardService,
    ],
    loadChildren: () =>
      import('./modules/reviewer/reviewer.module').then(
        (m) => m.ReviewerModule
      ),
  },
  {
    path: 'admin',
    data: {
      menuMode: MenuMode.Admin,
      expectedRole: 'admin',
    },
    component: AdminComponent,
    canActivate: [
      RequireLoginGuardService,
      MaintenanceLoginGuardService,
      RequireOnboardingGuardService,
      RoleGuardService,
      AdminModuleCheckGuardService,
    ],
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./shared/unauthorized/unauthorized.module').then(
        (m) => m.UnauthorizedModule
      ),
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
    loadChildren: () =>
      import('./core/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'loading',
    component: LoginLayoutComponent,
    canActivate: [RedirectLoginGuardService],
    loadChildren: () =>
      import('./core/loading/loading.module').then((m) => m.LoadingModule),
  },
  {
    path: 'low-security',
    canActivate: [RedirectLoginGuardService],
    loadChildren: () =>
      import('./core/low-security/low-security.module').then(
        (m) => m.LowSecurityModule
      ),
  },
  {
    path: 'logout',
    component: LoginLayoutComponent,
    loadChildren: () =>
      import('./core/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'trigger-error',
    component: TriggerErrorComponent,
    loadChildren: () =>
      import('./core/trigger-error/trigger-error.module').then(
        (m) => m.TriggerErrorModule
      ),
  },
  {
    path: '**',
    redirectTo: '/loading',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
