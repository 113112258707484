import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'integrity-trigger-error',
  templateUrl: './trigger-error.component.html',
  styleUrls: ['./trigger-error.component.scss'],
})
export class TriggerErrorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    throw new Error('ERROR');
  }
}
