import { Exclude, Expose, plainToInstance, Transform } from 'class-transformer';;
import { AgenciesAndGroupsTableRow } from './agencies-and-groups-table-row';

export class AgenciesTableRow extends AgenciesAndGroupsTableRow {
  @Expose({ name: 'surrogateKey' })
  agencyId: string;
  @Expose({ name: 'name' })
  agencyName: string;
  @Expose()
  @Transform(({ value }) => (value ? value.split(',') : []))
  ancestors: string[] = [];
  @Expose()
  @Transform(({ value }) => parseInt(value, 10), { toClassOnly: true })
  childrenCount: number;

  @Expose({name: 'surrogateKey'})
  agencySurrogateKey: string;
  
  @Exclude()
  isSelectAll = false;
  @Exclude()
  isGroup = false;
  
  static create(row: any): AgenciesTableRow {
  
    const agency = plainToInstance(AgenciesTableRow, row, {
      excludeExtraneousValues: true,
    });
    
    return agency;
  }
  
  constructor() {
    super();
  }

  get id(): string {
    return this.agencyId;
  }

  get name(): string {
    return this.agencyName;
  }

  @Exclude()
  isAgency: boolean = true;

  getAgencyHierarchy() {
    return [this.agencyId, ...this.ancestors];
  }

  getHierarchy() {
    return this.getAgencyHierarchy();
  }

}
