import { Injectable, NgZone } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

declare global {
  export interface Window {
    openTimeoutModal: any;
    closeTimeoutModal: any;
    openFlashModal: any;
    closeFlashModal: any;
    closeFlashModals: any;
    extendSession: any;
    endSession: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubWindowsManagerService {
  private openSubWindows: Window[] = [];
  public message = new Subject<MessageEvent>();
  subscription: Subscription;

  constructor(
    private ngZone: NgZone, // Since we're using global window functions
  ) {}

  public openSubWindow(url: string) {
    const windowReference = window.open(url);
    if (!windowReference) {
      // TODO
      //$rootScope.hostname = $location.host();
      //return angular.element('#allow-popups-modal').modal('show');
    } else {
      this.addSubWindow(windowReference);
      windowReference.focus();
    }
  }

  public closeSubWindow() {
    window.close();
  }

  public addSubWindow(ref: Window) {
    this.cleanSubWindows();
    this.addWindowListeners(ref);
    this.openSubWindows.push(ref);
  }
  
  public addWindowListeners(ref: Window) {
    ref.addEventListener(
      'message',
      this.receiveMessage.bind(this),
      false
    );
  }

  public closeSubWindows() {
    this.cleanSubWindows();

    this.openSubWindows.forEach((window) => {
      window.close();
    });

    this.openSubWindows = [];
  }

  public warnAndCloseSubWindows() {
    this.cleanSubWindows();

    const count = this.openSubWindows.length;

    if (count) {
      var message =
        (count > 1 ? 'Several other windows are' : 'One other window is') +
        ' currently open and will be closed when you leave this page. Any unsaved data will be lost. Are you sure you want to continue?';
      if (!confirm(message)) return false;
    }

    this.closeSubWindows();

    return true;
  }

  private cleanSubWindows() {
    this.openSubWindows = this.openSubWindows.filter((ref) => !ref.closed);
  }

  public getOpenSubWindows() {
    this.cleanSubWindows();

    return this.openSubWindows;
  }
  
  public resetListeners(): void {
    if (parent) {
      this.addWindowListeners(parent);
    }
  }

  public receiveMessage(message: MessageEvent) {
    this.ngZone.run(() => {
      this.message.next(message);
    });
  }
}
