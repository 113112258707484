import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '../services/session.service';
import { SessionCheckService } from '../services/session-check.service';
import { Router } from '@angular/router';
import { SubWindowsManagerService } from '../services/sub-windows-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'integrity-session-checker',
  templateUrl: './session-checker.component.html',
  styleUrls: ['./session-checker.component.scss'],
})
export class SessionCheckerComponent implements OnInit, OnDestroy {
  protected destroyed$: Subject<boolean> = new Subject();
  
  @ViewChild('sessionWarning') private modalContent: any;
  modal: NgbModalRef;

  modalTimeoutId: number;

  IDLE_WARNING_SECONDS = 5 * 60;
  
  titleIntervalId: number;
  titleBlinkRateMs = 500;

  originalTitle: string;
  isMainWindow: boolean;

  constructor(
    private modalService: NgbModal,
    private sessionService: SessionService,
    private sessionCheckService: SessionCheckService,
    private router: Router,
    private subWindows: SubWindowsManagerService,
    private ngZone: NgZone // Since we're using global window functions
  ) {

    // Session check is disabled when auth is bypassed for development, unless explicitly enabled by the developer
    if (sessionService.isAuthBypassed()) {
      if (localStorage.ENABLE_SESSION_CHECK) {
        console.warn(`Session check is currently enabled via localStorage override -- to remove this override, unset localStorage.ENABLE_SESSION_CHECK`);
      } else {
        console.log(`Session check is disabled by default when auth is bypassed -- to enable session check, set localStorage.ENABLE_SESSION_CHECK = true`);
        return;
      }
    }

    // Set this variable in Local Storage to override the number of idle seconds before the "extend my session" modal appears
    if (localStorage.IDLE_TIMEOUT_SECONDS) {
      console.warn(`Idle timeout currently set to ${localStorage.IDLE_TIMEOUT_SECONDS} seconds via localStorage.IDLE_TIMEOUT_SECONDS`);
      // NOTE: Override is already activated in sessionCheckService, we're just grouping related console messages here
    }

    // Set this variable in Local Storage to override the number of seconds the "extend my session" modal is shown before logout
    if (localStorage.IDLE_WARNING_SECONDS) {
      console.warn(`Idle warning modal timeout currently set to ${localStorage.IDLE_WARNING_SECONDS} seconds via localStorage.IDLE_WARNING_SECONDS`);
      this.IDLE_WARNING_SECONDS = Number(localStorage.IDLE_WARNING_SECONDS);
    }

    // Handler for extending sessions in multiple top-level windows
    localStorage.removeItem('SESSION_STATUS');
    window.addEventListener('storage',
      (event: StorageEvent) => {
        if (event.key === 'SESSION_STATUS') {
          if (localStorage.SESSION_STATUS === 'session extended') {
            this.extendSession();
          }
          if (localStorage.SESSION_STATUS === 'logged out') {
            this.endSession();
          }
        }
      });

    // All management of the modals across all sub windows is handled
    // in the main window
    this.isMainWindow = !window.opener;

    // Timeout actions are managed in the main window only
    if (this.isMainWindow) {

      this.sessionCheckService.idle.onTimeout.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        // When user has been idle, show the expiration warning
        console.log('Idle timeout. Showing warning modal.');

        // Show modal in main and all sub windows
        this.openTimeoutModals();

        // The modal wait for X minutes for user to extend or end the session
        this.startModalVisibleTimeout();

        // Blink the window title to bring attention to the expiration warning
        this.startTitleBlinkInterval();
      });

      // Function that sub windows will call if user extends session on one of the
      // sub window expiration warning modals
      window.extendSession = () => {
        this.ngZone.run(() => {
          this.extendSession();
        });
      };

      // Function that sub windows will call if user ends session on one of the
      // sub window expiration warning modals
      window.endSession = () => {
        this.ngZone.run(() => {
          this.endSession();
        });
      };
    } else {
      // Set up functions that the main window will call on the sub windows to
      // open close the sub windows' expiration warning modals

      // For opening the expiration warning
      window.openTimeoutModal = () => {
        this.ngZone.run(() => {
          this.openTimeoutModal();
          this.startTitleBlinkInterval();
        });
      };

      // For closing the expiration warning
      window.closeTimeoutModal = () => {
        this.ngZone.run(() => {
          this.clearModal();
        });
      };
    }

    // Start idle countdown
    this.reset();
  }

  reset() {
    this.sessionCheckService.idle.watch();
  }

  openTimeoutModal() {
    this.modal = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-session-title',
      backdrop: 'static',
      keyboard: false,
    });

    this.modal.result
      .then((result) => {
        // debugger;
      })
      .catch((error) => {
        // debugger;
      });
  }

  openTimeoutModals() {
    this.openTimeoutModal();

    // Show modal in each subwindow
    this.subWindows.getOpenSubWindows().forEach((subwindow) => {
      subwindow.openTimeoutModal();
    });
  }

  startModalVisibleTimeout() {
    this.modalTimeoutId = window.setTimeout(() => {
      this.endSession();
    }, this.IDLE_WARNING_SECONDS * 1000);
  }

  clearModalVisibleTimeout() {
    clearTimeout(this.modalTimeoutId);
  }

  startTitleBlinkInterval() {
    this.originalTitle = document.title;
    let toggle = false;
    this.titleIntervalId = window.setInterval(() => {
      document.title = toggle ? 'Session Ending Soon' : 'Attention! Attention!';
      toggle = !toggle;
    }, this.titleBlinkRateMs);
  }

  clearTitleBlinkInterval() {
    clearInterval(this.titleIntervalId);
    document.title = this.originalTitle;
  }

  ngOnInit(): void {}

  clearModal() {
    this.clearModalVisibleTimeout();
    this.clearTitleBlinkInterval();
    this.modal.close();
  }

  endSession() {
    if (this.isMainWindow) {
      console.log('Ending main window session');
      this.subWindows.closeSubWindows();
      if (this.modal) {
        this.clearModal();
      }
      if (this.sessionService.isLoggedIn()) {
        this.router.navigate(['logout']);
        this.sessionService.notifyOtherWindows('logged out');
      }
    } else if (window.opener != null) {
      console.log('Ending session from child');
      window.opener.endSession();
    }
  }

  extendSession() {
    this.sessionService.notifyOtherWindows('session extended');
    if (this.isMainWindow) {
      this.clearModal();
      this.subWindows.getOpenSubWindows().forEach((subwindow) => {
        subwindow.closeTimeoutModal();
      });
      this.reset();
    } else {
      this.ngZone.run(() => {
        if (window.opener != null) {
          window.opener.extendSession();
        }
      });
    }
  }

  ngOnDestroy(): void {
    window.onstorage = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
