import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface BroadcastMessage {
  type: string;
  payload?: any;
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  constructor() { }
  private handler: Subject<BroadcastMessage> = new Subject<BroadcastMessage>();

  broadcast(type: string, payload: any = null) {
    this.handler.next({ type, payload });
  }

  subscribe(type: string): Observable<BroadcastMessage> {
    return this.handler
      .pipe(
      filter(message => message.type === type)
      )
      .pipe(
       map(message => message.payload)
      );
  }
}
