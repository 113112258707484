import { instanceToPlain, Exclude, Expose } from 'class-transformer';
import { DateTime } from 'luxon';
import { Util } from '../util';
import _ from 'lodash-es';

export class ContactInfo {
  @Exclude()
  id: string;

  @Expose({ name: 'filer_firstName' })
  firstName: string;
  @Expose({ name: 'filer_middleInitial' })
  middleInitial?: string;
  @Expose({ name: 'filer_lastName' })
  lastName: string;
  @Expose({ name: 'filer_phone' })
  phone?: string;
  @Expose({ name: 'filer_emailPrimary' })
  email: string;
  @Expose({ name: 'filer_emailAlternate' })
  emailSecondary?: string;
  @Expose({ name: 'filer_streetAddress1' })
  address1: string;
  @Expose({ name: 'filer_streetAddress2' })
  address2?: string;
  @Expose({ name: 'filer_city' })
  city?: string;
  @Expose({ name: 'filer_state' })
  state?: string;
  @Expose({ name: 'filer_zip' })
  zip?: string;
  @Expose({ name: 'filer_country' })
  country?: string;
  @Expose({ name: 'filer_filingId' })
  filingId: string;
  @Expose({ name: 'filer_filingSecondaryId' })
  filingSecondaryId;
  @Expose({ name: 'filer_noDBconfirmemail' })
  confirmEmail: string;
  @Expose({ name: 'filer_noDBconfirmsecondemail' })
  confirmSecondaryEmail: string;

  @Exclude()
  maxLogin: string;
  @Expose({ name: 'filer_userAgreement' })
  userAgreement: Array<string> | null;

  @Exclude()
  cityStateZip?: string;
  @Exclude()
  updatedAt?: DateTime;
  @Exclude()
  raw: any;
  @Exclude()
  cloakedEmail?: string;
  

  static createFromDbRow(row: any): ContactInfo {
    const ci = new ContactInfo();

    ci.id = row.surrogate_key;
    ci.updatedAt = Util.parseDbTime(row.updated_at);
    ci.loadFromJson(row.data);

    return ci;
  }

  static createFromJson(json: any): ContactInfo {
    const ci = new ContactInfo();
    ci.loadFromJson(json);
    return ci;
  }

  static createFromMaxUser(json: any): ContactInfo {
    const ci = new ContactInfo();
    ci.id = json.id;
    ci.firstName = json.name.givenName;
    ci.middleInitial = json.name.middleName;
    ci.lastName = json.name.familyName;
    ci.email = json.emails
      .filter((email) => email.primary)
      .reduce((value, email) => value || email.value, '');
    ci.phone = json.phoneNumbers
      .filter((phoneNumber) => phoneNumber.primary)
      .reduce((value, phoneNumber) => value || phoneNumber.value, '');
    ci.address1 = json.addresses[0].streetAddress;
    ci.address2 = json.addresses[0].streetAddress2;
    ci.city = json.addresses[0].locality;
    ci.state = json.addresses[0].region;
    ci.zip = json.addresses[0].postalCode;

    return ci;
  }
  
  loadFromJson(input: any) {
    this.firstName = input.filer_firstName;
    this.middleInitial = input.filer_middleInitial;
    this.lastName = input.filer_lastName;
    this.phone = input.filer_phone;
    this.email = input.filer_emailPrimary;
    this.emailSecondary = input.filer_emailAlternate;
    this.address1 = input.filer_streetAddress1;
    this.address2 = input.filer_streetAddress2;
    this.city = input.filer_city;
    this.state = !!input.filer_state ? input.filer_state : null;
    this.zip = input.filer_zip;
    this.country = !!input.filer_country ? input.filer_country : null;
    this.setCityStateZip();
    this.userAgreement = input.filer_userAgreement;
    
    this.raw = input;
  }
  
  setCityStateZip(): void {
    const city = !!this.city ? this.city : '';
    const state = !!this.state ? this.state : '';
    const zip = !!this.zip ? this.zip : '';
    
    this.cityStateZip = (`${city}, ${state} ${zip}`)
      .replace(/\s\s/, ' ')
      .replace(/[,\s]*$/, '')
      .replace(/^,\s/, '');
  }
  
  public toJSON(): any {
    let plain = instanceToPlain(this);

    // If we have the original raw json from the database,
    // start with that and apply the current object's values on to it
    // This ensures we don't lose any untracked properties
    if (this.raw) {
      plain = _.merge(_.cloneDeep(this.raw), plain);
    }

    // remove nulls
    return _.omitBy(plain, _.isNil);
  }
  
  public get userAgreementIsSigned(): boolean {
    return (
      !!this.userAgreement &&
      Array.isArray(this.userAgreement) &&
      this.userAgreement.length &&
      this.userAgreement[0] == 'user_agreement'
    ) ? true : false;
  }
}

