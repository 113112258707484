import _ from 'lodash-es';
import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { LogService } from './services/log.service';

export class Util {
  static convertDateToString(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero based
    const year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }

  static convertDateToZeroPadString(date: Date): string {
    if (!(date instanceof Date)) {
      LogService.log('error', 'invalid input provided to convertDateToZeroPadString');
      console.log('-> invalid input provided to convertDateToZeroPadString');
      return '';
    }
    let day = date.getDate();
    let month = date.getMonth() + 1; //Months are zero based
    const year = date.getFullYear();
    return (
      (month < 10 ? '0' + month : month) +
      '/' +
      (day < 10 ? '0' + day : day) +
      '/' +
      year
    );
  }

  /**
   * Format for Permission Change Log - and any others?
   *
   * example return: 09/03/2021 01:02:08 pm
   */
  static convertDateTimeToZeroPadString(input: string): string {
    const [date, time, meridiem] = DateTime.fromISO(input, { zone: 'utc' })
      .setZone('America/New_York')
      .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      .replace(/,/, '')
      .toLowerCase()
      .split(' ');
    let formatted: string = '';

    if (!!date) {
      formatted += Util.convertDateToZeroPadString(new Date(date));
    }

    if (!!time) {
      var [hour, minutes, seconds] = time.split(':');
      formatted += ` ${Util.leftPadTwo(hour)}:${Util.leftPadTwo(
        minutes
      )}:${Util.leftPadTwo(seconds)}`;
    }

    if (!!meridiem) {
      formatted += ' ' + meridiem;
    }

    return formatted;
  }

  static leftPadTwo(input: string): string {
    return input.length < 2 ? '0' + input : input;
  }

  static convertStringToDate(str: string): Date | undefined {
    return !!str && str !== '' ? new Date(str) : undefined;
  }

  static parseDbTime(input: string): DateTime {
    //"2014-03-28T17:20:51.468+00:00"
    return DateTime.fromISO(input);
  }

  static timestampToDateTime(timestamp: number): DateTime {
    return DateTime.fromMillis(timestamp, {
      zone: 'America/New_York'
    });
  }

  static fixNulls(obj: any): any {
    if (_.isObject(obj)) {
      for (const v in obj) {
        if (_.isObject(obj[v])) {
          if (obj[v].hasOwnProperty('@nil') && obj[v]['@nil'] === 'true') {
            obj[v] = null;
          } else {
            obj[v] = Util.fixNulls(obj[v]);
          }
        } else if (_.isString(obj[v]) && 'null' === obj[v]) {
          obj[v] = null;
        }
      }
    }

    return obj;
  }

  static getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent!.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  static getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  static deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  static setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = ''
  ) {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cPath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cPath}`;
  }

  static sanitize(input: any): any {
    if (input) {
      if (typeof input === 'object') {
        for (const prop in input) {
          if (input.hasOwnProperty(prop)) {
            input[prop] = Util.sanitize(input[prop]);
          }
        }
      } else if (typeof input === 'string') {
        // Replace tabs and non-printing space characters with spaces
        input = input.replace(/[\t]/g, ' ');

        // Replace typographers single and double quotes with regular single and double quotes
        input = input.replace(/[\u2018\u2019]/g, "'");
        input = input.replace(/[\u201C\u201D]/g, '"');

        // Replace en and em dashes with hyphens
        input = input.replace(/\u2013|\u2014/g, '-');

        // Remove any embedded javascript
        input = input.replace(
          /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
          ''
        );

        // Replace all other invalid characters with nothing (final whitelist)
        input = input.replace(
          /[^a-zA-Z0-9 ,#@$%\^\*&+=|'";:<>`~\.\?\!\-\_\(\)\[\]\{\}\\\n\/]/gi,
          ''
        );

        // Strip out HTML tags
        input = input.replace(/(<[^ >]>)|(<[^ ]([^>]+)>)/gi, '');
      }
    }
    return input;
  }

  /**
   * Convert a "From Date" such as a filter value, to a timestamp.
   */
  static fromDateToTimestamp(date: string): number {
    const outDate = DateTime.fromFormat(date, 'MM/dd/yyyy')
      .startOf('day')
      .toUTC();

    return outDate.toMillis();
  }

  /**
   * Convert a "To Date" such as a filter value, to a timestamp.
   */
  static toDateToTimestamp(date: string): number {
    const outDate = DateTime.fromFormat(date, 'MM/dd/yyyy')
      .endOf('day')
      .toUTC();

    return outDate.toMillis();
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
  public static escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }


  public static getDateDiffFromToday(
    targetDate: string | null | undefined
  ): number | undefined {
    if (!targetDate) {
      return undefined;
    }
    const startOfDate = DateTime.fromFormat(targetDate, 'MM/dd/yyyy')
      .startOf('day')
      .toUTC();
    if (!startOfDate.isValid) {
      return undefined;
    }
    const today = DateTime.local().startOf('day').toUTC();
    const diff: number = startOfDate.diff(today, 'days').as('days');
    return diff;
  }

  public static calculateDateDiffInDays(date1: Date|undefined, date2: Date|undefined): number|undefined {
    if (!date1 || !date2) {
      return undefined;
    }
    
    return Math.floor((Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) 
      - Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) ) / (1000 * 60 * 60 * 24));
  }

}
