import {
  instanceToPlain,
  Exclude,
  Expose,
  plainToInstance,
  plainToClassFromExist,
  Transform,
  Type,
} from 'class-transformer';
import { IdGenService } from '../services/id-gen.service';
import { Agency } from './agency.model';
import { FilingTypeKey } from './filing-type-key.enum';
import { BaseGroupConfigs, GroupConfigs } from './group-configs.model';
import {
  RegularStaff,
  OGEOversightStaff,
  PPOWHCOStaff,
  Staff,
} from './group-staff.model';
import { Group } from './group.model';
import { Role, RoleKey } from './role.model';

export class BaseGroup {
  // Map to group table columns
  @Expose({ name: 'id' })
  surrogateKey: string = IdGenService.genUUID().replace(/-/g, '');
  name: string;

  @Expose({ name: 'parentId' })
  parentSurrogateKey: string;
  clonedFromId?: string;
  isAgency: boolean;

  session_user_id: string;
  sessionUserId: string;

  @Transform(({ value, obj }) => {
    if (!value) return value;

    const isAgency: boolean = obj.isAgency;
    if (isAgency) {
      const agencyType: string = obj.agencyType;
      switch (agencyType) {
        case 'PPO':
        case 'PTT':
        case 'WHCO':
          return plainToInstance(PPOWHCOStaff, obj.staff);
        case 'OGE-OVERSIGHT':
          return plainToInstance(OGEOversightStaff, obj.staff);
        default:
          return plainToInstance(RegularStaff, obj.staff);
      }
    } else {
      const groupType: string = obj.groupType;
      switch (groupType) {
        case 'NOMINEE-REGULAR':
          return plainToInstance(PPOWHCOStaff, obj.staff);
        // XXX: Need INBOUND-NOMINEE and OGE
        default:
          return plainToInstance(RegularStaff, obj.staff);
      }
    }
  })
  staff: Staff | null = new RegularStaff();

  @Type(() => BaseGroupConfigs)
  configs: BaseGroupConfigs = new BaseGroupConfigs();

  @Exclude()
  _parent?: BaseGroup;
  set parent(parent: BaseGroup | undefined) {
    this._parent = parent;
    if (parent) {
      this.parentSurrogateKey = parent.surrogateKey;
    }
  }
  @Exclude() get parent(): BaseGroup | undefined {
    return this._parent;
  }

  @Exclude()
  children?: BaseGroup[];

  @Exclude()
  version: number = -1;

  constructor() {}

  loadFromJson(json: any): void {
    plainToClassFromExist(this, json);

    const res = instanceToPlain(this);
    //debugger;
  }

  static createFromJson(json: any): BaseGroup {
    const group: BaseGroup = new BaseGroup();
    group.loadFromJson(json);
    return group;
  }

  public doesUserHoldAnyRole(id: string): boolean {
    if (this.staff) {
      const roles = Object.keys(this.staff);
      for (const role in roles) {
        if (Array.isArray(this.staff![role])) {
          if (this.staff[role].includes(id)) {
            return true;
          }
        } else {
          if (this.staff![role] == id) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public doesUserHoldRole(
    id: string,
    role: RoleKey,
    filingType: FilingTypeKey | undefined = undefined
  ): boolean {
    return !!this.staff && this.staff.doesUserHoldRole(role, id, filingType);
  }

  public getHierarchyGroupIds(): string[] {
    const ids: string[] = [this.surrogateKey];

    let parent = this.parent;

    while (parent) {
      ids.push(parent.surrogateKey);

      parent = parent.parent;
    }

    return ids;
  }

  public getAgency(
    group: BaseGroup | undefined = undefined
  ): Agency | undefined {
    const target = group ? group : this;
    if (!target.parent) {
      return target.isAgency ? (target as Agency) : undefined;
    } else {
      return this.getAgency(target.parent);
    }
  }

  public hasChildWithId(toFind: BaseGroup) {
    if (this.children && this.children.length) {
      return !!this.children.find(
        (child) => child.surrogateKey == toFind.surrogateKey
      );
    }

    return false;
  }

  public addChild(child: BaseGroup) {
    if (!this.children) {
      this.children = [];
    }
    this.children.push(child);
  }
}
