<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button
    (click)="activeModal.close(false)"
    aria-label="Close"
    class="modal-close"
    type="button"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <ul *ngIf="innerError && isArray">
          <li *ngFor="let err of innerError">{{ err }}</li>
        </ul>
        <p *ngIf="innerError && !isArray">{{ innerError }}</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    (click)="activeModal.close(false)"
    class="btn btn-outline-dark"
    type="button"
  >
    OK
  </button>
</div>
