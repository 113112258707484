import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectLoginGuardService {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.sessionService.isLoggedIn() && !this.sessionService.isInactive) {
      return true;
    }

    if (!this.sessionService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    if (this.sessionService.isInactive) {
      this.router.navigate(['/unauthorized']);
    }

    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.sessionService.isLoggedIn() && !this.sessionService.isInactive) {
      return true;
    }

    if (!this.sessionService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    if (this.sessionService.isInactive) {
      this.router.navigate(['/unauthorized']);
    }
    return false;
  }
}
