import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import {SessionService} from './session.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export enum LogLevel {
  ERROR = 'error',
  INFO = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private sessionService: SessionService,
    private http: HttpClient
  ) {}

  public static log(
    type: string,
    message: string | { statusMessage: string }
  ): void {
    switch (type) {
      case 'error': {
        console.error(JSON.stringify(message));
        if (ConfigService.DEBUG) {
          if (
            typeof message !== 'string' &&
            message.hasOwnProperty('statusMessage')
          ) {
            alert('ERROR: ' + message.statusMessage);
          } else {
            alert('ERROR: ' + message);
          }
        }
        break;
      }
      case 'info': {
        console.log('INFO: %s', message);
        break;
      }
      default: {
        console.log(message);
      }
    }
  }

  /**
   * Output to error_log.
   * Stack is n/a when no error column property can be used for other text output. 
   */
  public remoteLog(message: string, stack: string | null = null): void {
    const userAgent = {
      language: navigator.language,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
    };
    
    const body = {
      timestamp: Date.now(),
      message: message,
      userId: this.sessionService.getMaxUsername(),
      location,
      userAgent,
      stack: stack,
    };

    this.http
      .post(ConfigService.LOG_ERROR, body, this.getOptions())
      .subscribe(() => {
      });
  }

  private getOptions(): any {
    return { headers: this.getHeaders() };
  }

  private getHeaders(): HttpHeaders {
    return this.sessionService
      .getAuthHeader()
      .append('x-session-user-id', this.sessionService.getMaxUsername());
  }
}
