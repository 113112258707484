import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'integrity-reviewer',
  templateUrl: './reviewer.component.html',
  styleUrls: ['./reviewer.component.scss'],
})
export class ReviewerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
