import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, map, takeUntil, catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { CookieService } from 'ngx-cookie-service';
import { Role, RoleType } from '../models/role.model';
import { ProfileData } from '../models/profile-data.model';

interface NodeOptions {
  headers: {};
  params: { sessionUserId: string };
}

@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {
  CASKEY = 'Y2Fza2V5'; // base 64 encoded string "caskey"
  bypassAuthMaxId: string | null = null; // jsmith
  loggedIn = false;
  isInactive = false;
  sessionData: any;
  maxId: string;
  menuToggles: string[] = [];
  hasNonDesigneeRole = false;
  rolesData: Role[];
  roles: string[] = [];
  profileData: ProfileData;
  private casVal: string | null;
  protected destroyed$: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {}

  public static decodeResults(results: any) {
    let out;
    if (results === '' || (results !== null && results.rows == null)) {
      out = [];
    } else if (!!results && results.rows instanceof Array) {
      out = results.rows;
    } else {
      out = [results?.rows];
    }
    // Translate DSS nulls and booleans into native Javascript values...
    for (const i in out) {
      if (out.hasOwnProperty(i)) {
        for (const j in out[i]) {
          if (out[i].hasOwnProperty(j)) {
            if (out[i][j] != null && out[i][j]['@nil'] === 'true') {
              out[i][j] = null;
            } else {
              if (j === 'is_primary' || j === 'is_inherited') {
                out[i][j] = out[i][j] === 'true';
              }
            }
          }
        }
      }
    }
    return out;
  }

  public init() {
    console.log('Initializing SessionService');

    this.casVal = this.getSessionIdQueryParam();
    if (this.casVal) {
      this.cookieService.set(this.CASKEY, this.casVal, undefined, '/');
    } else {
      this.casVal = this.cookieService.get(this.CASKEY);
    }

    if (!this.casVal && !this.isAuthBypassed()) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      this.retrieveSessionData()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          this.saveSessionData(data);

          if (data && ![42, 32].includes(data.status)) {
            // VALID SESSION
            this.loggedIn = true;

            this.retrieveProfileData()
              .pipe(takeUntil(this.destroyed$))
              .subscribe((data2) => {
                this.saveProfileData(data2);

                if (this.isInIntegrityAccessGroup()) {
                  // IN INTEGRITY ACCESS GROUP
                  this.retrieveCanonicalId()
                    .pipe(takeUntil(this.destroyed$))
                    .pipe(
                      mergeMap((results) => {
                        this.parseCanonicalId(results);

                        return forkJoin({
                          roles: this.retrieveRoleData(),
                          toggles: this.retrieveToggleData(),
                        });
                      })
                    )
                    .subscribe({
                      next: (results) => {
                        this.parseRoles(results.roles);
                        this.parseToggles(results.toggles);

                        resolve();
                      },
                      error: (error) => {
                        resolve();
                      },
                    });
                } else {
                  // NOT IN INTEGRITY ACCESS GROUP
                }
              });
          } else {
            // INVALID SESSION
            resolve();
          }
        });
    });
  }

  public isLoggedIn() {
    return this.loggedIn;
  }

  public isAuthBypassed(): boolean {
    return !!this.bypassAuthMaxId;
  }

  public getRolesData(): Role[] {
    return this.rolesData;
  }

  public getNonFilingRolesData() {
    const nonFilingRoleKeys = [
      'OGE_ADMINISTRATOR',
      'OGE_DIRECTOR',
      'OGE_RECORDS_MANAGER',
      'SYSTEM_ADMINISTRATOR',
      'SYSTEM_SUPERUSER',
    ];
    return this.rolesData.filter((role: any) => {
      return nonFilingRoleKeys.includes(role.role_surrogate_key);
    });
  }

  public getCasMaxUsername(): string {
    return this.profileData.casMaxId;
  }

  public getMaxUsername(): string {
    return this.profileData.maxId;
  }

  public getMaxFirstname(): string {
    return this.profileData.firstName;
  }

  public getMaxLastname(): string {
    return this.profileData.lastName;
  }

  public getMaxEmail(): string {
    return this.profileData.email;
  }

  public setUploadAuthCookie() {
    if (this.casVal) {
      this.cookieService.set('upload-auth-key', this.casVal, undefined, '/');
    }
  }

  public deleteUploadAuthCookie() {
    this.cookieService.delete('upload-auth-key', '/');
  }

  public getAuthHeader(): HttpHeaders {
    return new HttpHeaders({ Authorization: this.getCas() });
  }

  getCas(): string {
    return this.cookieService.get(this.CASKEY);
  }

  private saveSessionData(data: any) {
    this.sessionData = data;
  }

  private saveProfileData(data: any) {
    this.profileData = ProfileData.fromJson(data.results);
  }

  private parseToggles(toggleData: any) {
    if (!!toggleData && Array.isArray(toggleData)) {
      for (const toggleD of toggleData) {
        const toggles: string[] =
          !!toggleD && !!toggleD.toggle ? toggleD.toggle.split(',') : [];
        toggles.forEach((toggle: string) => {
          toggle = toggle.toLowerCase();
          if (!this.menuToggles.includes(toggle)) {
            this.menuToggles.push(toggle);
          }
        });
      }
    }

    // Record if the user actually has a role granting menu access
    this.hasNonDesigneeRole = this.menuToggles.length > 0;

    // Users without any roles (such as Designees) get the "filer" tab.
    if (this.menuToggles.length === 0) {
      this.menuToggles.push('filer');
    }
  }

  private parseRoles(rolesData: Role[]) {
    this.rolesData = rolesData;
    for (const role of rolesData) {
      if (role.type === RoleType.Filer && !this.roles.includes('filer')) {
        this.roles.push('filer');
      }
      if (role.type === RoleType.Reviewer && !this.roles.includes('reviewer')) {
        this.roles.push('reviewer');
      }
      if (
        (role.type === RoleType.AgencyAdministrator ||
          role.type === RoleType.PointOfContact ||
          role.type === RoleType.RecordsManager ||
          role.type === RoleType.SystemAdministrator) &&
        !this.roles.includes('admin')
      ) {
        this.roles.push('admin');
      }
    }
  }

  private parseCanonicalId(canonical: any[]) {
    if (
      canonical !== undefined &&
      canonical.length &&
      canonical[0] !== undefined &&
      canonical[0].id
    ) {
      this.profileData.maxId = canonical[0].id;
    }
  }

  private retrieveSessionData(): Observable<any> {
    console.log('In SessionService::retrieveSessionData()...');

    if (this.bypassAuthMaxId) {
      return of({
        status: 0,
      });
    } else {
      return this.http.get<any>(ConfigService.SESSION_USERS_JSON, {});
    }
  }

  private getMockProfileData() {
    return {
      results: {
        'MAX-ID': this.bypassAuthMaxId,
        'CAS-MAX-ID': this.bypassAuthMaxId,
        'First-Name': 'John',
        'Last-Name': 'Smith',
        'Email-Address': 'john.smith@tcg.com',
        GroupList: ConfigService.INTEGRITY_ACCESS_GROUP_ID,
      },
    };
  }

  private retrieveProfileData(): Observable<any> {
    if (this.bypassAuthMaxId) {
      return of(this.getMockProfileData());
    } else {
      return this.http.get<any>(ConfigService.SESSION_PROFILE_JSON, {});
    }
  }

  private retrieveRoleData(): Observable<Role[]> {
    return this.http
      .get<any>(
        ConfigService.INTEGRITY_SERVICE_GET_USER_ROLES,
        this.getNodeOptions()
      )
      .pipe(
        map((result) => {
          return Role.mapDatabaseResult(result);
        })
      );
  }

  private retrieveToggleData(): Observable<any> {
    return this.http
      .get<any>(
        ConfigService.INTEGRITY_SERVICE_GET_USER_TOGGLES,
        this.getNodeOptions()
      )
      .pipe(map((result) => SessionService.decodeResults(result.results)));
  }

  private retrieveCanonicalId(): Observable<any> {
    return this.http
      .get<any>(
        ConfigService.INTEGRITY_SERVICE_GET_CANONICAL_ID,
        this.getNodeOptions()
      )

      .pipe(
        catchError((error) => {
          this.isInactive = true;
          return error;
        }),
        map((result) => SessionService.decodeResults(result.results))
      );
  }

  private isInIntegrityAccessGroup() {
    return (
      this.profileData.inGroup(ConfigService.INTEGRITY_ACCESS_GROUP_ID) &&
      this.profileData.maxId !== 'INACTIVE'
    );
  }
  public hasFilerMenuToggle() {
    return this.menuToggles.includes('filer');
  }

  public hasAdminMenuToggle() {
    return this.menuToggles.includes('admin');
  }

  public hasReviewerMenuToggle() {
    return this.menuToggles.includes('reviewer');
  }

  public hasRole(target: string, targetAgency: string = ''): boolean {
    let hasRole: boolean = false;
    const rolesData: Array<Role> = this.getRolesData();

    rolesData.forEach((role) => {
      if (!targetAgency && role.surrogateKey == target) {
        hasRole = true;
      }
      if (
        !!targetAgency &&
        role.agencyType == targetAgency &&
        role.surrogateKey == target
      ) {
        hasRole = true;
      }
    });

    return hasRole;
  }

  public hasRoleType(roleType: string) {
    let hasRoleType = false;
    this.roles.forEach((role) => {
      if (roleType === role) {
        hasRoleType = true;
      }
    });
    return hasRoleType;
  }

  public hasRoleKey(roleKey: string): boolean {
    return this.rolesData.reduce((hasRole, role) => {
      return hasRole || role.surrogateKey === roleKey;
    }, false);
  }

  getSessionIdQueryParam(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get('sessionId');
  }

  public getCasVal() {
    return this.casVal;
  }

  private deleteSession(): Observable<any> {
    this.cookieService.delete(this.CASKEY);

    // this sessionStorage variable might be used in development
    sessionStorage.removeItem('MAXID');

    if (!this.isAuthBypassed()) {
      this.sessionData = null;
      this.profileData = new ProfileData();
      this.rolesData = [];
    }

    return this.http.delete<any>(ConfigService.SESSION_USERS_JSON);
  }

  public notifyOtherWindows(message: string) {
    localStorage.setItem('SESSION_STATUS', message);
    // wait a moment for actions to propagate, then clear notification
    setTimeout(() => localStorage.removeItem('SESSION_STATUS'), 3000);
  }

  public logout() {
    this.deleteSession()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {});
    this.notifyOtherWindows('logged out');
  }

  public getNodeOptions(): NodeOptions {
    return {
      headers: this.getNodeHeader(),
      params: { sessionUserId: this.profileData.maxId },
    };
  }

  public getNodeHeader(): any {
    return {
      'x-session-user-id': this.getMaxUsername(),
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
