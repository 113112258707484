import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Document } from './document.model';
import _ from 'lodash';
import { TableData } from './table-data';
import {MonthYearDatePipe} from '../pipes/month-year-date.pipe';

export class MonthYear {
  month: number | string = '';
  year: number | string = '';

  constructor(monthYear: any) {
    if (typeof monthYear == 'object' && monthYear != null) {
      if (monthYear.hasOwnProperty('month')) {
        this.month = monthYear.month;
      }
      if (monthYear.hasOwnProperty('year')) {
        this.year = monthYear.year;
      }
    }
  }

  toJson(): any {
    let json: any | undefined = {};
    for (const prop of Object.keys(this)) {
      if (this[prop] != undefined && this[prop] != '') {
        json[prop] = (this as any)[prop];
      }
    }

    if (!Object.keys(json).length) {
      json = undefined;
    }

    return json;
  }
}

export class FilingIndicativeData extends TableData {

  // Not passed to Node
  public NOT_PASSED = [
    'requireJobDescription',
    'job_description',
    'name_check',
    'name_check_nominee',
    'new',
    'version',
    'source_layer',
    'sequenceId',
    'initialAppointmentDate',
    'appointmentDate',
  ];
  
  report_hasSpouse: string = '';
  report_positionTerminationDate: string | null = null; // ngbDatePicker fields must be null rather than empty string
  report_middleInitial: string | null = null;
  report_middle_initial: string | null = null; // 278_0_T_Cover_Page version
  report_reportDueDate: string = '';
  report_groupName: string = '';
  report_positionAppointmentDate: string | null = null; // ngbDatePicker fields must be null rather than empty string
  report_agencyName: string = '';
  report_firstName: string | null = null;
  report_first_name: string | null = null; // 278_0_T_Cover_Page version
  filingId: string = '';
  report_filerCategory: string = '';
  report_positionTitle: string = '';
  report_lastName: string | null = null;
  report_last_name: string | null = null; // 278_0_T_Cover_Page version
  report_positionAppointmentDate2: MonthYear = new MonthYear({});
  report_appointmentType: string | null = null; // OGE-8372
  initialAppointmentDate: string | MonthYear | null;
  version = '1';

  requireJobDescription: boolean = false;
  job_description: Document | null = null;

  name_check: string;
  name_check_nominee: string;
  new = false;

  constructor() {
    super(null);
  }

  static createNew(report: any): FilingIndicativeData {
    const indicativeData = new FilingIndicativeData();

    indicativeData.updateFromJson(report);

    return indicativeData;
  }
  
  static createFromJson(data: any): FilingIndicativeData {
    const indicativeData = new FilingIndicativeData();
    indicativeData.updateFromJson(data);
    return indicativeData;
  }

  public updateFromJson(data: any) {
    this.report_hasSpouse = !!data.report_hasSpouse
      ? data.report_hasSpouse
      : this.report_hasSpouse;
    this.report_positionTerminationDate = !!data.report_positionTerminationDate
      ? data.report_positionTerminationDate
      : this.report_positionTerminationDate;
    this.setMiddleInitial(data);
    this.setDueDate(data);
    this.setGroupName(
      !!data.report_groupName ? data.report_groupName : this.report_groupName
    );
    this.report_positionAppointmentDate = !!data.report_positionAppointmentDate
      ? data.report_positionAppointmentDate
      : this.report_positionAppointmentDate;
    this.setAgencyName(
      !!data.report_agencyName ? data.report_agencyName : this.report_agencyName
    );
    this.setFirstName(data);
    this.filingId = !!data.filingId ? data.filingId : this.filingId;
    this.setFilerCategory(data);
    this.setPosition(data);
    this.setLastName(data);
    this.report_positionAppointmentDate2 =
      !!data.report_positionAppointmentDate2
        ? new MonthYear(data.report_positionAppointmentDate2)
        : this.report_positionAppointmentDate2;

    this.requireJobDescription = !!data.requireJobDescription
      ? data.requireJobDescription
      : this.requireJobDescription;
    this.job_description = !!data.job_description
      ? data.job_description
      : this.job_description;
    
    this.report_appointmentType = !!data.report_appointmentType
     ? data.report_appointmentType : this.report_appointmentType;
    
    this.initialAppointmentDate = !!data.initialAppointmentDate 
      ? data.initialAppointmentDate : !!data.initialAppointmentDate2 
      ? new MonthYear(JSON.parse(data.initialAppointmentDate2)) : this.initialAppointmentDate;
    
    this.version = !!data.version ? data.version : this.version;
  }

  public setFirstName(data: any) {
    this.report_firstName = !!data.filer_firstName
      ? data.filer_firstName
      : this.report_firstName;
    this.report_firstName =
      !!data.report_firstName && !this.report_firstName
        ? data.report_firstName
        : this.report_firstName;
    this.report_first_name = !!data.report_first_name
      ? data.report_first_name
      : this.report_first_name;
  }

  public setMiddleInitial(data: any) {
    this.report_middleInitial = !!data.filer_middleInitial
      ? data.filer_middleInitial
      : this.report_middleInitial;
    this.report_middleInitial =
      !!data.report_middleInitial && !this.report_middleInitial
        ? data.report_middleInitial
        : this.report_middleInitial;
    this.report_middle_initial = !!data.report_middle_initial
      ? data.report_middle_initial
      : this.report_middle_initial;
  }

  public setLastName(data: any) {
    this.report_lastName = !!data.filer_lastName
      ? data.filer_lastName
      : this.report_lastName;
    this.report_lastName =
      !!data.report_lastName && !this.report_lastName
        ? data.report_lastName
        : this.report_lastName;
    this.report_last_name = !!data.report_last_name
      ? data.report_last_name
      : this.report_last_name;
  }

  public setFilerCategory(data: any) {
    this.report_filerCategory = !!data.report_filerCategory
      ? data.report_filerCategory
      : this.report_filerCategory;
    this.report_filerCategory =
      !!data.filerCategory && !this.report_filerCategory
        ? data.filerCategory
        : this.report_filerCategory;
  }

  public setPosition(data: any) {
    this.report_positionTitle = !!data.report_positionTitle
      ? data.report_positionTitle
      : this.report_positionTitle;
    this.report_positionTitle =
      !!data.position && !this.report_positionTitle
        ? data.position
        : this.report_positionTitle;
  }

  public setDueDate(data: any) {
    this.report_reportDueDate = !!data.due
      ? data.due
      : this.report_reportDueDate;
    this.report_reportDueDate =
      !!data.report_reportDueDate && !this.report_reportDueDate
        ? data.report_reportDueDate
        : this.report_reportDueDate;
  }

  public setGroupName(groupName: string) {
    this.report_groupName = groupName;
  }

  public setAgencyName(agencyName: string) {
    this.report_agencyName = agencyName;
  }

  /**
   * Set the values based on the object structure returned from WSOService getGroupAndParentAgency
   */
  public setGroupAndParentAgency(groupAndParentAgency: any) {
    this.setGroupName(groupAndParentAgency.group.name);
    this.setAgencyName(groupAndParentAgency.agency.name);
  }
  
  toJson(): any {
    let json: any = {};
    for (const key of Object.keys(this)) {
      switch (key) {
        case 'report_positionAppointmentDate2':
        case 'job_description':          
          json[key] = (!!this[key]) ? this[key]?.toJson() : undefined;
          break;
         
        default: {
          json = this.propToJson(json, key, this.NOT_PASSED);
        }
      }
    }
    json = _.omitBy(json, _.isNil);
    json = _.omitBy(json, _.isUndefined);
    return json;
  }

  /**
   * Update the model's data from the submitted form
   */
  public updateFromSubmitted(form: UntypedFormGroup) {
    for (const field in form.controls) {
      const control: UntypedFormControl = <UntypedFormControl>form.get(field);

      if (control && !control.pristine && this.hasOwnProperty(field)) {
        let value: any = this.getValueFromForm(form, field);
        switch (field) {
          case 'report_positionAppointmentDate2':
            this[field] = new MonthYear(value);
            break;

          default:
            this[field] = value;
        }
      }
    }
  }

  public getValueFromForm(form: UntypedFormGroup, field: string) {
    let value: any = '';

    switch (field) {
      case 'report_positionAppointmentDate2':
        value = new MonthYear(form.get(field)!.value);
        break;

      default:
        value = form.get(field)!.value;
    }

    return value;
  }

  /**
   * EFEDS-6084 Appointment Date comes from a different field for New Entrant Report and for that report is in the
   * form mm/dd/YY.
   * For other types, the Appointment Date field has a month and year component.
   */
  get appointmentDate(): string {
    if (
      this.report_positionAppointmentDate &&
      this.report_positionAppointmentDate.length
    ) {
      return this.report_positionAppointmentDate;
    }

    const appointment = this.report_positionAppointmentDate2;

    const datePipe = new MonthYearDatePipe();
   
    if (!!appointment && !!appointment.month && !!appointment.year) {
      return datePipe.transform(appointment);
    }

    return '';
  }
  
  public get prepopulatedJobDescriptionWarning(): boolean {
    return !!this.job_description && 
     this.job_description.prepopulatedWarning;
  }
}


export class Filing278TIndicativeData extends FilingIndicativeData {

  static createNew(report: any): Filing278TIndicativeData {
    const indicativeData = new Filing278TIndicativeData();
    indicativeData.updateFromJson(report);
    return indicativeData;
  }
  
  toJson(): any {
    const json = super.toJson();
    delete json.report_hasSpouse;
    
    return json;
  }
}
