import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuMode } from '../models/menu-mode.enum';

@Injectable({
  providedIn: 'root',
})

/**
 * Show/hide the menu component.
 *
 * https://stackoverflow.com/questions/43118592/angular-2-how-to-hide-nav-bar-in-some-components
 */
export class MenuService {
  menuMode: MenuMode;
  visible: boolean = true;
  _showOnlyClose = false;
  rebuildMenu$ = new EventEmitter();

  constructor(private router: Router) {
    this.show();
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }

  showOnlyClose() {
    this._showOnlyClose = true;
  }

  convertMode(url: string): MenuMode {
    if (url.includes('/filer/filing')) {
      return MenuMode.Filing;
    } else if (url.includes('/reviewer/filing')) {
      return MenuMode.ReviewFiling;
    } else if (url.includes('/filer')) {
      return MenuMode.Filer;
    } else if (url.includes('/reviewer')) {
      return MenuMode.Reviewer;
    } else if (url.includes('/admin')) {
      return MenuMode.Admin;
    }
    throw new Error(`Menu mode not available for url=${url}`);
  }

  determineMode(url: string): void {
    try {
      this.menuMode = this.convertMode(url);
    } catch (e) {
      console.log('--> unknown menu mode in determineMode for url ' + url);
    }
  }

  get mode(): MenuMode {
    if (!this.menuMode) {
      this.determineMode(this.router.url);
    }

    return this.menuMode;
  }

  public rebuildMenu() {
    this.rebuildMenu$.emit();
  }
}
