<ng-template #content>
  <li>
    <span class="white" *ngIf="filerName">
      <i class="fas fa-user"></i>
      <span class="title">{{ filerName }}</span>
    </span>
  </li>

  <li
    *ngFor="let item of menuItems?.items"
    [ngClass]="{ active: item.isActive }"
  >
    <a href="" (click)="$event.preventDefault()">
      <i [ngClass]="item.iconClass"></i>
      <span class="title">{{ item.title }}</span>
    </a>
    <ul *ngIf="item.items?.length" class="sub-menu">
      <ng-container *ngFor="let subItem of item.items">
        <li *ngIf="subItem.isEnabled" [ngClass]="{ active: subItem.isActive }">
          <a *ngIf="subItem.action" (click)="callAction(subItem, subItem.action)" [routerLink]="null">
            {{ subItem.title }}
          </a>
          <a *ngIf="!subItem.action" (click)="go(subItem)" [routerLink]="null">
            {{ subItem.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </li>

  <li>
    <a (click)="closeWindow()" [routerLink]="[]">
      <i class="fas fa-times-circle"></i>
      <span class="title">CLOSE WINDOW</span>
    </a>
  </li>
</ng-template>
