import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Role, RoleKey, RolesForFiling, RoleType } from '../models/role.model';
import { ReportStatus } from '../models/status.model';
import { Filing, Filing278 } from '../models/filing.model';
import { GroupAdmin } from '../../modules/admin/models/group-admin.model';
import { FilingTypeKey } from '../models/filing-type-key.enum';

const canBulkAssignReportRoles: Array<string> = [
  'administrator',
  'alternateAdministrators',
  'daeo',
  'alternateDaeos',
];

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private sessionService: SessionService) {}

  public canAccessRecordsManagement() {
    return this.sessionService.getRolesData().reduce((show, role) => {
      return show || this.canRoleAccessRecordsManagement(role);
    }, false);
  }

  private canRoleAccessRecordsManagement(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyDAEO:
      case RoleKey.OGECertifyingOfficial:
      case RoleKey.OGEDirector:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.OGENomineeRecordsManager:
      case RoleKey.OGERecordsManager:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.RecordsManager:
      case RoleKey.SystemAdministrator:
      case RoleKey.SystemSuperuser:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return true;
      default:
        return false;
    }
  }

  public canAccessAnnualDataExtract() {
    return this.sessionService.getRolesData().reduce((show, role) => {
      return show || this.canRoleAccessAnnualDataExtract(role);
    }, false);
  }

  private canRoleAccessAnnualDataExtract(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyDAEO:
      case RoleKey.AgencyAdministrator:
      case RoleKey.SystemAdministrator:
      case RoleKey.SystemSuperuser:
        return true;
      default:
        return false;
    }
  }

  public canRunAnnualDataExtractForAgency(agency: string): boolean {
    return this.sessionService.getRolesData().reduce((show, role) => {
      return show || this.canRoleRunAnnualDataExtractForAgency(role, agency);
    }, false);
  }

  private canRoleRunAnnualDataExtractForAgency(
    role: Role,
    agency: string
  ): boolean {
    switch (role.surrogateKey) {
      case RoleKey.SystemAdministrator:
      case RoleKey.SystemSuperuser:
        return true;
        break;
      case RoleKey.AgencyAdministrator:
      case RoleKey.AgencyDAEO:
        if (role.group.surrogateKey == agency) {
          return true;
        }
        return false;
        break;

      default:
        return false;
        break;
    }
  }

  private canRoleEditGeneralInformation(role: Role, filing: Filing): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.OGERecordsManager:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.RecordsManager:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return false;
      case RoleKey.NomineeReviewer:
        // EFEDS-5565 Nominee Reviewer should not be able to edit reports beyond the draft stage.
        const status: ReportStatus = filing.workflow.status.status;
        if (
          filing.item === 'Nominee Report' &&
          (status === ReportStatus.DraftUnderReview ||
            status === ReportStatus.DraftPreCleared)
        ) {
          return true;
        }
        return false;
      default:
        return true;
    }
  }

  public canEditGeneralInformation(
    filing: Filing,
    rolesForFiling: RolesForFiling
  ): boolean {
    // First check if the report is an unreleased nominee report. In that case, none of the PPO/PTT/WHCO roles can edit.
    if (filing.item == 'Nominee Report') {
      const status: ReportStatus = filing.workflow.status.status;

      if (
        [
          ReportStatus.NotStarted,
          ReportStatus.Draft,
          ReportStatus.DraftPendingRelease,
        ].includes(status)
      ) {
        let role: Role;
        for (role of rolesForFiling.roles) {
          switch (role.surrogateKey) {
            case RoleKey.PPOLead:
            case RoleKey.PPOReviewer:
            case RoleKey.PTTLead:
            case RoleKey.PTTReviewer:
            case RoleKey.WHCOLead:
            case RoleKey.WHCOReviewer:
              return false;
          }
        }
      }
    }

    // If report is not a nominee report, check if this is a filer viewing a submitted report
    else if (filing.workflowStep > 1) {
      if (rolesForFiling.hasRole(RoleKey.Filer)) {
        return false;
      }
    }

    return rolesForFiling.roles.reduce((verdict: boolean, role: Role) => {
      return verdict || this.canRoleEditGeneralInformation(role, filing);
    }, false);
  }

  public canEditMyFilingPart(filing: Filing): boolean {
    if (filing.userId == this.sessionService.getMaxUsername()) {
      if (filing.isNomineeReport()) {
        return true;
      } else {
        return filing.assignedTo == this.sessionService.getMaxUsername();
      }
    }
    return false;
  }

  public isUserInGroups(
    groups: Array<GroupAdmin>,
    rolesToSearch: Array<string>
  ): boolean {
    let found: boolean = false;
    for (let i = 0; i < groups.length; i++) {
      const group: GroupAdmin = groups[i];
      if (group && !!group.staff && this.isUserInGroup(group, rolesToSearch)) {
        found = true;
        break;
      }
    }

    return found;
  }

  public isUserInGroup(
    group: GroupAdmin,
    rolesToSearch: Array<string>
  ): boolean {
    const userId: string = this.sessionService.getMaxUsername();

    let found: boolean = false;

    if (!Array.isArray(rolesToSearch) || !rolesToSearch.length) {
      return found;
    }

    for (let i = 0; i < rolesToSearch.length; i++) {
      const role: string = rolesToSearch[i];
      if (group.userHasStaffRole(userId, role)) {
        found = true;
        break;
      }
    }

    return found;
  }

  public canViewReportAssignments(groups: GroupAdmin[]): boolean {
    const rolesToSearch: string[] = [
      'contact',
      'alternateContacts',
      'general278supervisor',
      'general278alternateSupervisors',
      'general278reviewer',
      'general278alternateReviewers',
      'confirmed278reviewer',
      'confirmed278alternateReviewers',
      'router',
      'alternateRouters',
      'general278ethicsOfficial',
      'general278alternateEthicsOfficials',
      'confirmed278ethicsOfficial',
      'confirmed278alternateEthicsOfficials',
      'general278certifyingOfficial',
      'general278alternateCertifyingOfficials',
      'confirmed278certifyingOfficial',
      'confirmed278alternateCertifyingOfficials',
      'daeo',
      'alternateDaeos',
      'administrator',
      'alternateAdministrators',
      'ppoReviewer',
      'alternatePpoReviewers',
      'ppoLead',
      'pttReviewer',
      'alternatePttReviewers',
      'pttLead',
      'whcoReviewer',
      'alternateWhcoReviewers',
      'whcoLead',
    ];

    return this.isUserInGroups(groups, rolesToSearch);
  }

  public canAssignReports(groups: GroupAdmin[]): boolean {
    const rolesToSearch: string[] = [
      'general278supervisor',
      'general278alternateSupervisors',
      'general278reviewer',
      'general278alternateReviewers',
      'confirmed278reviewer',
      'confirmed278alternateReviewers',
      'router',
      'alternateRouters',
      'general278ethicsOfficial',
      'general278alternateEthicsOfficials',
      'confirmed278ethicsOfficial',
      'confirmed278alternateEthicsOfficials',
      'general278certifyingOfficial',
      'general278alternateCertifyingOfficials',
      'confirmed278certifyingOfficial',
      'confirmed278alternateCertifyingOfficials',
      'daeo',
      'alternateDaeos',
      'administrator',
      'alternateAdministrators',
      'ppoReviewer',
      'alternatePpoReviewers',
      'ppoLead',
      'pttReviewer',
      'alternatePttReviewers',
      'pttLead',
      'whcoReviewer',
      'alternateWhcoReviewers',
      'whcoLead',
    ];

    return this.isUserInGroups(groups, rolesToSearch);
  }

  public canEditReportAssignmentNotification(groups: GroupAdmin[]): boolean {
    let canEdit: boolean = true;

    let specialAgency: boolean = false;
    groups.forEach((group) => {
      if (['PPO', 'WHCO', 'PTT'].includes(group.agencyType)) {
        specialAgency = true;
      }
    });

    if (!specialAgency) {
      return canEdit;
    }

    const rolesToSearch: string[] = [
      'ppoReviewer',
      'alternatePpoReviewers',
      'ppoLead',
      'pttReviewer',
      'alternatePttReviewers',
      'pttLead',
      'whcoReviewer',
      'alternateWhcoReviewers',
      'whcoLead',
      'administrator',
      'alternateAdministrators',
    ];

    if (this.isUserInGroups(groups, rolesToSearch)) {
      canEdit = false;
    }

    return canEdit;
  }

  canEditAssignedReports(groups: GroupAdmin[]): boolean {
    const rolesToSearch: string[] = [
      'alternatePpoReviewers',
      'alternatePttReviewers',
      'alternateWhcoReviewers',
      'ppoLead',
      'ppoReviewer',
      'pttLead',
      'pttReviewer',
      'whcoLead',
      'whcoReviewer',
    ];

    return !this.isUserInGroups(groups, rolesToSearch);
  }

  public roleIsOGE(role: Role): boolean {
    return /OGE_.*/.test(role.surrogateKey) ? true : false;
  }

  public canSeeAllTargetAgencies(): boolean {
    return this.sessionService.getRolesData().reduce((show, role) => {
      return show || this.canRoleSeeAllTargetAgencies(role);
    }, false);
  }

  public canRoleSeeAllTargetAgencies(role: Role): boolean {
    if (this.roleIsOGE(role)) {
      return true;
    }

    switch (role.surrogateKey) {
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.WHCOReviewer:
      case RoleKey.WHCOLead:
        return true;
      case RoleKey.AgencyAdministrator:
        if (role.isSpecialAgencyType()) {
          return true;
        }
        return false;

      default:
        return false;
    }

    return false;
  }

  public canBulkAssignReports(groups: Array<GroupAdmin>): boolean {
    return (
      this.hasRole('System Administrator') ||
      this.isUserInGroups(groups, canBulkAssignReportRoles)
    );
  }

  public hasRole(roleName: string): boolean {
    return this.sessionService.hasRole(roleName);
  }

  public canAssignFilingTypeReports(
    filingType: FilingTypeKey,
    groupSurrogateKey: string,
    agencySurrogateKey: string
  ): boolean {
    return this.sessionService
      .getRolesData()
      .reduce((canAssign: boolean, role: Role) => {
        return (
          canAssign ||
          this.canRoleAssignReport(
            role,
            filingType,
            groupSurrogateKey,
            agencySurrogateKey
          )
        );
      }, false);
  }

  private canRoleAssignReport(
    role: Role,
    filingType: FilingTypeKey,
    groupSurrogateKey: string,
    agencySurrogateKey: string
  ): boolean {
    if (role.group.surrogateKey == groupSurrogateKey) {
      // Reviewers in the same workflow
      if (role.type == RoleType.Reviewer && role.filingType == filingType) {
        return true;
      }

      // Roles not specific to a filing type which can assign a report
      switch (role.surrogateKey) {
        case RoleKey.PointOfContact:
        case RoleKey.PPOLead:
        case RoleKey.PTTLead:
        case RoleKey.WHCOLead:
          return true;
        default:
          return false;
      }
    }

    // Agency DAEO or Admin
    if (role.group.surrogateKey == agencySurrogateKey) {
      switch (role.surrogateKey) {
        case RoleKey.AgencyAdministrator:
        case RoleKey.AgencyDAEO:
          return true;
        default:
          return false;
      }
    }

    return false;
  }

  public canEditPartOnReviewReport(
    filing: Filing278,
    rolesForFiling: RolesForFiling
  ): boolean {
    // First check if the report is an unreleased nominee report. In that case, none of the PPO/PTT/WHCO roles can edit.
    if (filing.isNomineeReport()) {
      const status = filing.workflow.status.status;

      if (
        [
          ReportStatus.NotStarted,
          ReportStatus.DraftPreReview,
          ReportStatus.DraftPendingRelease,
        ].includes(status)
      ) {
        for (let r = 0; r < rolesForFiling.roles.length; r++) {
          const role = rolesForFiling.roles[r];

          switch (role.surrogateKey) {
            case RoleKey.PPOLead:
            case RoleKey.PPOReviewer:
            case RoleKey.PTTLead:
            case RoleKey.PTTReviewer:
            case RoleKey.WHCOLead:
            case RoleKey.WHCOReviewer:
              return false;
          }
        }
      }
    }

    return rolesForFiling.roles.reduce((show, role) => {
      return show || this.canRoleEditPartOnReviewReport(role);
    }, false);
  }

  private canRoleEditPartOnReviewReport(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return false;
      default:
        return true;
    }
  }

  public canUserLoginDuringMaintenance(): boolean {
    return (
      this.hasRole(RoleKey.SystemAdministrator) ||
      this.hasRole(RoleKey.SystemSuperuser)
    );
  }
  
  public userMustConfirmProceedWithoutLateFee(
    rolesForFiling: RolesForFiling
  ): boolean {
    
    return rolesForFiling.roles.reduce((verdict: boolean, role: Role) => {
      return verdict || this.mustRoleConfirmProceedWithoutLateFee(role);
    }, false);
  }


  private mustRoleConfirmProceedWithoutLateFee(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.CertifyingOfficial:
        return true;
     
      default:
        return false;
    }
  }
}
