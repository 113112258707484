import { Expose } from 'class-transformer';
import { TableRow } from '../../../models/table-row.model';

export abstract class AgenciesAndGroupsTableRow extends TableRow {
  abstract get id(): string;
  abstract get name(): string;

  @Expose()
  
  expanded = false;
  isAgency = false;
  childrenCount = 0;
  parent: AgenciesAndGroupsTableRow;
  children: AgenciesAndGroupsTableRow[] = [];
  checked: boolean;
  isSelectAll = false;
  isGroup = false;
  agencySurrogateKey: string;
  
  abstract getHierarchy(): string[];

  public getAgency(
    group
  ): AgenciesAndGroupsTableRow | undefined {
    
    const target = group ? group : this;
    if (!target.parent) {
      return target.isAgency ? target : undefined;
    } else {
      return this.getAgency(target.parent);
    }
  }
 
  get isTopLevel(): boolean {
    return false;
  }
  
  get isSubgroup(): boolean {
    return false;
  }
}
