import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionCheckService } from './session-check.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class FilerModuleCheckGuardService
  implements CanActivate, CanActivateChild
{
  constructor(
    private sessionService: SessionService,
    private sessionCheckService: SessionCheckService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.check(state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.check(state.url);
  }

  check(url: string) {
    if (!this.sessionCheckService.userIsAuthorized()) {
      this.router.navigateByUrl('/unauthorized');
    }
     
    // Onboarding is open to users who have at least one role. 
    if (url.includes('/filer/onboarding')) {
      this.sessionCheckService.hasSeenOnboarding = true;
      return true;
    }

    if (!this.sessionService.hasFilerMenuToggle()) {
      this.router.navigateByUrl('/loading');
      return false;
    }
    return true;
  }
}
