<ng-template #content>
  <li>
    <span class="white" *ngIf="filerName">
      <i class="fas fa-user"></i>
      <span class="title">{{ filerName }}</span>
    </span>
  </li>

  <ng-container *ngFor="let item of menuItems?.items">
    <li *ngIf="item.isEnabled" [ngClass]="{ active: item.isActive }">
      <a href="" (click)="$event.preventDefault()">
        <i [ngClass]="item.iconClass"></i>
        <span class="title">{{ item.title }}</span>
        <i
          *ngIf="item.flagFunction && item.flagEnabled"
          [id]="item.flagFunction"
          class="fas fa-check"
        ></i>
      </a>
      <ul *ngIf="item.items?.length" class="sub-menu">
        <ng-container *ngFor="let subItem of item.items">
          <li
            *ngIf="subItem.isEnabled"
            [ngClass]="{ active: subItem.isActive }"
          >
            <a
              *ngIf="subItem.action"
              (click)="callAction(subItem, subItem.action)"
              [routerLink]="null"
            >
              {{ subItem.title }}
              <i *ngIf="subItem.flagEnabled" class="fa fa-check"></i>
            </a>
            <a *ngIf="!subItem.action" (click)="go(subItem)" [routerLink]="null">
              {{ subItem.title }}
              <i *ngIf="subItem.flagEnabled" class="fa fa-check"></i>
            </a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ng-container>

  <li>
    <a (click)="closeWindow()" [routerLink]="[]">
      <i class="fas fa-times-circle"></i>
      <span class="title">CLOSE WINDOW</span>
    </a>
  </li>
</ng-template>

<form
  method="POST"
  action="{{ downloadComparePriorReportUrl }}"
  id="downloadComparePriorReportForm"
  #downloadComparePriorReportForm
  ngNoForm
>
</form>
