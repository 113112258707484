export interface Routing {
  addUrl?: string;
  addAnotherUrl?: string;
  nextUrl?: string;
  previousUrl?: string;
  doneUrl?: string;
  conditions?: Condition[];
  execute?: Execute[];
}

export interface Condition {
  type: string;
  linkKey: string;
  linkUrl: string;
  toggle?: string;
  formId?: string;
  field?: string;
  value?: any;
  callback?: string;
  elementId?: string;
  elementType?: string;
  input?: any;
}

export interface Execute {
  callback: string;
}

export interface GroupSurrogateKey {
  action: string;
  destination: string | NamesDestination;
}

export interface NamesDestination {
  filer_lastName: string;
  filer_firstName: string;
  filer_middleInitial: string;
}

export interface Field {
  destination: string;
  overwrite: boolean;
}

export interface Fields {
  position?: string;
  filerCategory?: string;
  groupSurrogateKey?: GroupSurrogateKey[];
  maxLoginEmail?: Field;
  termination?: string;
  appointment?: string;
  due?: Field;
  organizationName?: Field;
  positionHeld?: Field;
}

export interface AdditionalFormValues {
  id: string;
  source: string;
  fields: Fields;
}

export interface Tooltip {
  title: string;
  text: string;
}

export interface SectionForm {
  formId: string;
  title?: string;
  checkboxField?: string;
  checkboxValue?: string;
  descriptionField?: string;
  yesNoField?: string;
  yesNoValue?: string;
  versions?: {
    '201901': string;
  };
}

export interface SectionForms {
  employee_officer_director_corporation: SectionForm[];
  attorney_law_firm: SectionForm[];
  sole_proprietor: SectionForm[];
  professor_or_dean: SectionForm[];
  employee: SectionForm[];
  consultant: SectionForm[];
  trustee: SectionForm[];
  other: SectionForm[];
}

export interface Form {
  formId: string;
  versions?: {
    '201901': string;
  };
}

export class ConfigModel {
  formRouting: Routing;
  reviewerRouting?: Routing;
  additionalFormValues?: AdditionalFormValues;
  saveCreatedBy?: boolean;
  doNotAddFilingIds?: boolean;
  buttonText?: string;
  companyName?: string;
  noDataSaved?: boolean;
  deleteUnderlyingAssetsWithoutParent?: boolean;
  noRecordsFlag?: string;
  tooltips?: Map<string, Tooltip | Map<string, Tooltip>>;
  sectionForms?: SectionForms;
  forms?: Map<string, Form>;

  constructor(json: any) {
    for (const prop in json) {
      if (prop === 'tooltips') {
        this.tooltips = new Map();
        for (const tooltipProp in json.tooltips) {
          if (!!json.tooltips[tooltipProp].versions) {
            // handle tooltips that have multiple versions
            const versionMap = new Map<string, Tooltip>();
            for (const versionProp in json.tooltips[tooltipProp].versions) {
              versionMap.set(
                versionProp,
                json.tooltips[tooltipProp].versions[versionProp]
              );
            }
            this.tooltips.set(tooltipProp, versionMap);
          } else {
            this.tooltips.set(tooltipProp, json[prop][tooltipProp]);
          }
        }
      } else if (prop === 'forms') {
        this.forms = new Map();
        for (const formProp in json.forms) {
          this.forms.set(formProp, json.forms[formProp]);
        }
      } else {
        (this as any)[prop] = json[prop];
      }
    }
  }

  getTooltip(tooltipId: string, rulesVersion?: number): Tooltip | undefined {
    const tooltip = this.tooltips?.get(tooltipId);
    if (!!tooltip) {
      if (tooltip instanceof Map) {
        for (let [version, tip] of tooltip) {
          if (version === 'default') {
            return tip;
          }
          if (!!rulesVersion && parseInt(version) <= rulesVersion) {
            return tip;
          }
        }
        return undefined;
      } else {
        return tooltip;
      }
    } else {
      return undefined;
    }
  }
}
