import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'integrity-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss'],
})
export class UnsavedChangesModalComponent implements OnInit {
  @Input() scope: string = 'entry';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
