import { plainToInstance } from 'class-transformer';
import { FilingType } from './filing-type.model';

export class WorkflowType {
  surrogateKey: string;
  label: string;
  filingTypeSurrogateKey: string;

  filingType?: FilingType;

  public static createFromJson(
    json: any,
    filingTypes: Map<string, FilingType> | null = null
  ): WorkflowType {
    const type = plainToInstance(WorkflowType, json);
    if (
      filingTypes &&
      type.filingTypeSurrogateKey &&
      filingTypes.has(type.filingTypeSurrogateKey)
    ) {
      type.filingType = filingTypes.get(type.filingTypeSurrogateKey)!;
    }
    return type;
  }
}
