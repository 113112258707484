import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SessionCheckService } from './shared/services/session-check.service';
import { SessionService } from './shared/services/session.service';
import { SubWindowsManagerService } from './shared/services/sub-windows-manager.service';
import { Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'integrity-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'integrity';
  isLoggedIn = false;
  protected destroyed$: Subject<boolean> = new Subject();
  
  constructor(
    private sessionCheckService: SessionCheckService,
    private subWindows: SubWindowsManagerService,
    private sessionService: SessionService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.sessionService.isLoggedIn();

    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((e) => {
         this.subWindows.resetListeners();
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    if (this.subWindows.getOpenSubWindows().length) {
      event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      // @ts-ignore
      event.returnValue = '';
    } else {
      // @ts-ignore
      delete event.returnValue;
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: Event) {
    this.subWindows.closeSubWindows();
  }
  
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
