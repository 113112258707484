<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">UNSAVED DATA</h4>
  <button
    (click)="activeModal.close(false)"
    aria-label="Close"
    class="modal-close"
    type="button"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row g-0">
      <div class="col-lg-12">
        You have unsaved data. Are you sure you want to leave this {{ scope }}?
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close(false)" class="btn" type="button">
    No
  </button>
  <button (click)="activeModal.close(true)" class="btn yellow" type="button">
    Yes
  </button>
</div>
